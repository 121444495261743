<template>
  <div>
    <iframe 
    class="bg" title="myIframe" src="about.html" 
    marginwidth="0" marginheight="0"></iframe>
    <Loading></Loading>
  </div>
</template>

<script>
import Loading from '../components/Loading'
export default {
    name:'MyAbout',
    components:{Loading},
}
</script>

<style scoped>
.bg{
    width: 100vw;
    height: 100vh;
    position: static;
}
.frame{
  height: 80vh;
  z-index: -999;
}
</style>