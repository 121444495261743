<template>
<div>
    <!-- 表格 -->
    <div class="section chinese_font" style="padding: 20px;">
        <!-- 页头 -->
        <div class="flex_row" style="justify-content: flex-start;">
            <div class="flex_row" style="width: 25vw;justify-content: flex-start;">
                <el-page-header style="margin-bottom: 20px;"
                @back="goBack" content="编辑题库"></el-page-header>                
            </div>
            <div class="flex_row" style="width: 75vw;">
                <el-radio-group v-model="type">
                    <el-radio-button label="1">选择题</el-radio-button>
                    <el-radio-button label="2">填空题</el-radio-button>
                    <el-radio-button label="3" disabled>编程题</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <!-- 主体 -->
        <div class="flex_row" style="align-items: flex-start;">
            <!-- 左区域 -->
            <div class="left_section infinite-list flex_column" style="overflow:auto">
                <div class="flex_row timu_list">
                    <div 
                    :class="current_timu==index?'timu_box_active':'timu_box'" 
                    @click="choose_timu(index)"
                    v-for="(timu,index) in timu_list" :key="index">{{index+1}}
                    </div>
                </div>
                <!-- 新增一个空题目 -->
                <i @click="addTimu" 
                class="el-icon-circle-plus-outline add_timu"></i>
            </div>
            <!-- 右区域(infinite-list：element的无限滚动样式) -->
            <div class="right_section infinite-list flex_column" style="overflow:auto">
                <!-- 标题 -->
                <el-input
                    style="margin-bottom: 20px;font-size: 18px;"
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 4, maxRows: 7}"
                    placeholder="请输入标题"
                    v-model="title">
                </el-input>
                <!-- 选择题选项 -->
                <div v-show="type==1">
                    <el-input placeholder="选项A必填" v-model="A" style="margin-bottom: 20px;">
                        <template slot="prepend">A</template>
                    </el-input>
                    <el-input placeholder="选项B必填" v-model="B" style="margin-bottom: 20px;">
                        <template slot="prepend">B</template>
                    </el-input>
                    <el-input placeholder="如果是判断题，C可以不填" v-model="C" style="margin-bottom: 20px;">
                        <template slot="prepend">C</template>
                    </el-input>
                    <el-input placeholder="如果是判断题，D可以不填" v-model="D" style="margin-bottom: 20px;">
                        <template slot="prepend">D</template>
                    </el-input>
                    <!-- 正确选项 -->
                    <div class="flex_row">
                        <span>正确选项：</span>
                        <el-radio-group v-model="correct">
                            <el-radio-button label="A"></el-radio-button>
                            <el-radio-button label="B"></el-radio-button>
                            <el-radio-button label="C"></el-radio-button>
                            <el-radio-button label="D"></el-radio-button>
                        </el-radio-group>
                    </div>
                </div>
                <!-- 填空题 -->
                <div v-show="type==2">
                    <el-form label-position="left" label-width="80px" :model="filling">
                        <el-form-item label="答案1">
                            <el-input placeholder="必填" 
                            v-model="filling.answer1"></el-input>
                        </el-form-item>
                        <el-form-item label="答案2">
                            <el-input placeholder="选填"
                            v-model="filling.answer2"></el-input>
                        </el-form-item>
                        <el-form-item label="答案3">
                            <el-input placeholder="选填" 
                            v-model="filling.answer3"></el-input>
                        </el-form-item>
                        <el-form-item label="答案4">
                            <el-input placeholder="选填" 
                            v-model="filling.answer4"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <!-- 操作按钮 -->
                <div style="margin-top: 20px;">
                    <el-button @click="submit" v-if="timu_id"
                    type="primary" plain>修改</el-button>
                    <el-button @click="submit" v-else
                    type="success" plain>新增</el-button>
                    <el-button @click="Delete($event,timu_id)"
                    type="danger" plain>删除</el-button>
                    <el-button @click="reset()"
                    type="info" plain>重置</el-button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import { Base64 } from "js-base64"; // 引入 js-base64 库
export default {
    name:'StoreEdit',
    data() {
        return { 
            timu_list:[{
                title:'',A:'',B:'',C:'',D:'',correct:'A',type:1,
                filling: {  //填空题
                    answer1: '',
                    answer2: '',
                    answer3: '',
                    answer4: ''
                }
            }],
            current_timu:0,  //当前选中的题目
            title:'',
            A:'',
            B:'',
            C:'',
            D:'',
            timu_id:'',   
            correct:'A',       //正确选项
            type:1,            //题目类型，1选择题，2填空题
            filling: {  //填空题
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: ''
            },
        }
    },
    methods: {
        // 重置当前题目
        reset(){
            this.title = ''
            this.A = ''
            this.B = ''
            this.C = ''
            this.D = ''
            this.filling = {
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: ''
            }
        },
        // 新增题目/修改题目
        submit(event){
            let titleEncode = Base64.encode(this.title)
            // 前端表单校验
            if(!this.title){
                this.$message({
                    type:'warning',
                    message:'前端:标题没填'
                })
                event.preventDefault()
                return false
            }
            // 如果是选择题
            if(this.type==1){
                if(!this.A){
                    this.$message({
                        type:'warning',
                        message:'前端:A选项没填'
                    })
                    event.preventDefault()
                    return false
                }
                if(!this.B){
                    this.$message({
                        type:'warning',
                        message:'前端:B选项没填(至少设置两个选项的)'
                    })
                    event.preventDefault()
                    return false
                }
                if(!this.correct){
                    this.$message({
                        type:'warning',
                        message:'前端:正确选项没填'
                    })
                    event.preventDefault()
                    return false
                }
            }
            // 如果是填空题
            else if(this.type==2){
                if(!this.filling.answer1){
                    this.$message({
                        type:'warning',
                        message:'前端:答案1没填'
                    })
                    event.preventDefault()
                    return false
                }
            }
            // 发起请求
            let params = {
                timu_id: this.timu_id,     // 只有数据库已有的题目有id，有id为修改，无id为新增
                store_id: this.$route.query.store_id, // 从前一个页面传过来的题库id
                title: titleEncode,
                A: this.A,
                B: this.B,
                C: this.C,
                D: this.D,
                correct: this.correct,
                store_name: this.$route.query.store_name,
                filling:Base64.encode(JSON.stringify(this.filling)),
                type:this.type 
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Exam_timu_add.php',
                params:params
            }).then(
                response => {
                    if(response.data.error == 0){
                        this.$message({
                            type:'success',
                            message:response.data.msg
                        })
                        // 0表示修改,1表示新增
                        if(response.data.type==0){
                            this.getTimuList(0) 
                        }else{
                            this.getTimuList(1)
                        }
                    }else{
                        this.$message.error(response.data.msg)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )   
        },
        // 获得题目列表
        getTimuList(type){
            // 发起请求
            let params = {
                store_id: this.$route.query.store_id, // 从前一个页面传过来的题库id
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Exam_timu_get.php',
                params:params
            }).then(
                response => {
                    console.log('题目列表',response)
                    // 初次打开没有数据 
                    if(response.data){
                        this.timu_list = response.data.data
                        // 解码
                        for (let i in this.timu_list){
                            this.timu_list[i].title = Base64.decode(this.timu_list[i].title)
                            this.timu_list[i].filling = JSON.parse(Base64.decode(this.timu_list[i].filling))
                        }
                    }
                    // type==0:修改，后端请求过来后，更新一下数据，选中项不变
                    if(type==0){
                        this.choose_timu(this.current_timu)
                    // type==1:新增，新增一个空项，然后选中那个空项
                    }else if(type==1){
                        // 新增一个空题目
                        this.addTimu()
                    // type==2:删除，回到上一项
                    }else if(type==2){
                        this.current_timu -= 1
                        this.choose_timu(this.current_timu)
                    // 否则就是初次打开，选中第一项
                    }else{
                        this.choose_timu(0)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 新增一个空题目
        addTimu(){
            // 最多只能添加一个空题目
            if(this.timu_list[this.timu_list.length-1].title==''){
                this.$message({
                    type:'warning',
                    message:'前端:已经存在一个空的题目了'
                })
            // 添加空题目
            }else{
                this.timu_list.push({
                    title:'',A:'',B:'',C:'',D:'',correct:'A',type:1,
                    filling: {  //填空题
                        answer1: '',
                        answer2: '',
                        answer3: '',
                        answer4: ''
                    }
                })
                // 选中最后一个(即刚新增的题目)
                this.current_timu = this.timu_list.length-1 
                this.choose_timu(this.current_timu)
            }
        },
        // 用户选择题目
        choose_timu(index){
            this.current_timu = index
            this.title = this.timu_list[index].title
            this.timu_id = this.timu_list[index].id
            this.A = this.timu_list[index].A
            this.B = this.timu_list[index].B
            this.C = this.timu_list[index].C
            this.D = this.timu_list[index].D
            this.correct = this.timu_list[index].correct
            this.type = this.timu_list[index].type
            this.filling = this.timu_list[index].filling
        },
        //返回上一层
        goBack() {
            this.$router.go(-1);
        },
        // 删除题目
        Delete(event,timu_id){
            // 如果已经current_timu是0，说明是第一项，
            // 如果再删的话，数据库删空了，就请求不到数据，getTimuList()无法更新数据
            // 但实际上数据库已经删光了，只需要前端显示清除一下
            if(this.current_timu==0){
                this.$set(this.timu_list,0,{title:'',A:'',B:'',C:'',D:'',correct:'A'})
                this.choose_timu(0)
            }
            // 发送请求
            let params = {
                dataSheet: 'exam_store_timu',
                id: timu_id,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Delete.php',
                params:params
            }).then(
            response => {
                console.log('删除',response)
                this.getTimuList(2)
                // 删除成功提示
                this.$message({
                    type: 'success',
                    message: response.data.msg
                });
            },
            error => {
                console.log('请求失败了',error.message)
            })
        },
    },
    mounted(){
        this.getTimuList()
    }
}
</script>

<style scoped>
.section{
    width: 75%;
    margin-left: 15%;
    margin-top: 3vh;
    background-color: white;
    height: 85vh;
}
.left_section{
    width: 25%;
    height: 65vh;
    border-right: 1px solid #c7c7c7;
    justify-content: flex-start;
}
.right_section{
    width: 75%;
    padding: 0 4vw;
}
.timu_list{
    flex-flow: wrap;
    justify-content: flex-start;
}
.timu_box{
    box-sizing: border-box;
    width: 40px;
    padding: 10px 12px;
    margin: 10px;
    border: 1px solid lightgray;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
}
.timu_box_active{
    box-sizing: border-box;
    width: 40px;
    padding: 10px 12px;
    margin: 10px;
    border: 1px solid #409EFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    text-align: center;
}
.add_timu{
    font-size: 32px;
}
</style>