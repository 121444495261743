<template>
<div class="flex_column">
    <div style="margin-bottom: 20px;color: rgb(252, 61, 61);">注意: 一旦上传图片即扣除300积分，上架费一旦缴纳，概不退还</div>
    <el-form label-position="left" label-width="80px">
        <!-- 头像名 -->
        <el-form-item label="头像名">
            <el-input v-model.trim="avatar_name"  maxlength="8"
            placeholder="请输入头像名称"></el-input>
        </el-form-item>
        <!-- 头像描述 -->
        <el-form-item label="头像描述">
            <el-input v-model.trim="avatar_des" maxlength="20"
            placeholder="请输入头像描述"></el-input>
        </el-form-item>
        <el-form-item label="售价">
            <el-input v-model.trim="price" maxlength="5" type="number"
            placeholder="请输入售价"></el-input>
        </el-form-item>
    </el-form>
    <!-- 头像上传 -->
    <el-upload
        style="border: 1px dashed #d9d9d9;margin-bottom: 20px;"
        class="avatar-uploader"
        action="https://xiaolily.cn/File_add_shop.php"
        name="shop_file"
        :data="{'avatar_name':avatar_name,
        'avatar_des':avatar_des,
        'price':price}"
        :show-file-list="false"
        :on-success="handleAvatarSuccess"
        :before-upload="beforeAvatarUpload">
        <img v-if="imageUrl" :src="imageUrl" class="avatar">
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
</div>
</template>
    
<script>
import axios from 'axios'
export default {
    name:'StoreAdd',
    props:['getDataList','handleClose'],
    data() {
        return { 
            avatar_name: '',            //头像名
            avatar_des:'',              //头像描述
            imageUrl: '',
            price: ''
        }
    },
    methods: {
        // 用户头像上传
        handleAvatarSuccess(response, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
            console.log(this.imageUrl)
            console.log(response)
            if(response.error==0){
                this.$message({
                    type:'success',
                    message:response.msg
                })
                 this.handleClose()
                 this.getDataList()
            }else{
                this.$message({
                    type:'warning',
                    message:response.msg
                })
            }
        },
        // 上传前的钩子
        beforeAvatarUpload(file) {
            if(this.price<0){
                this.$message.error('售价不能为负');
                return false;
            }
            // 头像和头像描述检测
            if(!this.avatar_name||!this.avatar_des||!this.price){
                this.$message.error('请先填写头像名、头像描述、售价');
                return false;
            }
            // 文件类型检测
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 1;
            if (!isJPG && !isPNG) {
                this.$message.error('上传头像图片只能是JPG格式或PNG格式');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 1MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
    }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
.avatar-uploader .el-upload:hover {
border-color: #409EFF;
}
.avatar-uploader-icon {
font-size: 28px;
color: #8c939d;
width: 178px;
height: 178px;
line-height: 178px;
text-align: center;
}
.avatar {
width: 178px;
height: 178px;
display: block;
}
</style>