<template>
<div>
    <header>
        <!-- 首页视频背景 -->
        <video id="banner" autoplay="autoplay" loop="loop" muted="muted">
            <source :src="banner" type="audio/mp4">
        </video>
        <!-- 滤镜 -->
		<div :class="filter_dot"></div>
		<!-- 波浪线 -->
        <div id="banner_wave_1"></div><div id="banner_wave_2"></div>
        <!-- 中心区域 -->
		<div id="header_center" class="flex_column">
            <!-- 网站标题 -->
			<transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__bounceInDown">
				<h1 class=" center_text no_wrap glitch" 
				data-text="dark code">dark code</h1>
            </transition>
            <!-- 首页一言打字效果 -->
			<transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__rubberBand">
				<div class="header-info no_wrap chinese_font">
					<i class="fa fa-quote-left"></i>
					<span class="element">疯狂输入中......</span>
					<i class="fa fa-quote-right"></i>
					<span class="element"></span>
				</div>
			</transition>
			<!-- 左右箭头切换css滤镜 -->
			<transition
            appear
            name="animate__animated animate__bounce"
            enter-active-class="animate__rubberBand">
				<div class="arrow_section flex_row">
					<img src="../assets/image/arrow.svg" class="arrow"
					style="transform: rotate(180deg);" @click="before_img">
					<img src="../assets/image/arrow.svg" class="arrow" @click="next_img">
				</div>
			</transition>
        </div>
		<!-- 右侧区域、登录状态栏 -->
		<transition
		appear
		name="animate__animated animate__bounce el-fade-in"
		enter-active-class="animate__flipInY">
			<aside id="header-right" v-if="$store.state.username">
				<!-- 头像&修改头像 -->
				<el-popover
				placement="right"
				width="410"
				trigger="click"
				@hide="choose_avatar()"
				>
				<!-- <ul class="infinite-list" v-infinite-scroll="load" style="height: 200px;overflow:auto"> -->
					<el-radio-group v-model="avatar">
						<!-- label相当于原生中的value属性 -->
						<el-radio class="infinite-list-item" v-for="(avatar,index) in avatar_list" :key="index" :label="avatar">  
							<el-avatar shape="square" :size="80" 
							:src="avatar">
							</el-avatar>
						</el-radio>
						<!-- 分页 -->
						<div class="pagination">
							<el-pagination
							small
							background
							@size-change="handleSizeChange"
							@current-change="handleCurrentChange"
							:current-page.sync="currentPage"
							:page-sizes="[9, 18, 27, 36]"
							:page-size="9"
							layout="prev, pager, next"
							:total="total">
							</el-pagination>
						</div>
					</el-radio-group>
				<!-- </ul> -->
					<!-- <el-avatar slot="reference" shape="square" :size="80" style="margin-top: 10px;"
					:src="require('../assets/touxiang/'+$store.state.avatar)">
					</el-avatar> -->
					<el-avatar slot="reference" shape="square" 
					:size="80" style="margin-top: 10px;"
					:src="$store.state.avatar"
					fit="fill">
					</el-avatar>
				</el-popover>
				<!-- 用户名&修改密码 -->
				<div class="flex_row">
					<p class='logged_username chinese_font'>欢迎你 {{$store.state.username}} </p>
					<img 
					@click="mod_password"
					src="../assets/image/modify.png" 
					class="icon_img" 
					style="margin: 20px 0 0 10px;"
					>
				</div>
				<!-- 管理权限&随机文字 -->
				<p class='admin_name chinese_font'>{{$store.state.admin_name}}</p>   
				<!-- <p id="motivational_text">{{motivational_text}}</p> -->
				<!-- 等级 -->
				<div class="statistic_section flex_row chinese_font grey">
					<span>Lv{{lv}}：</span>
					<el-progress style="width: 40%;" :stroke-width="20" text-inside
					:percentage="ex_percent"></el-progress>
				</div>
				<!-- 统计数据 -->
				<div class="flex_row" style="width: 80%;margin-bottom: 15px;">
					<el-statistic class="statistic_box"
					group-separator="," :precision="2"  title="考试平均分">
						<template slot="formatter"> {{exam_score_ave}} </template>
					</el-statistic>
					<el-statistic class="statistic_box"
					group-separator="," :precision="2"  title="作业绩点">
						<template slot="formatter"> {{task_rate_ave}} </template>
					</el-statistic>
					<el-statistic class="statistic_box"
					group-separator="," :precision="2"  title="积分">
						<template slot="formatter"> {{jifen}} </template>
					</el-statistic> 
					<!-- 积分提示 -->
					<el-tooltip class="item" effect="dark" 
					content="" 
					placement="top-start">
						<div slot="content">考试一次最多100积分
							<br/>作业点赞一个赞1积分
							<br/>作业评分四星+50积分,五星+100积分
							<br/>点名回答问题一次50积分起
							<br/>获得积分的同时也会获得经验,100经验升一级（除了点赞）
						</div>
						<i class="el-icon-question"></i>
					</el-tooltip>
				</div>
				<!-- 注销&管理 -->
				<div class="flex_row" style="justify-content: space-around;width: 80%;">
					<el-button type="primary" plain @click="Logout">退出</el-button>
					<router-link to="Admin/User_admin">
						<el-button type="primary" plain>管理</el-button>
					</router-link>
				</div>
			</aside>
		</transition>
		<!-- 首页下拉箭头 -->
		<div class="headertop-down faa-float animated" @click="headertop_down">
			<span><i class="fa fa-chevron-down" aria-hidden="true"></i></span>
		</div>
    </header>
    <Task></Task>
	<Loading></Loading>
</div>
</template>

<script>
import axios from 'axios'
import Loading from '../components/Loading'
import Task from './Task/index'
export default {
    name:"MyIndex",
	components:{Loading,Task},
    data(){
        return{
			filter_dot:'filter_dot2',      // 滤镜样式
			random_number:2,               // 滤镜的随机数
			avatar:'',                     // 用户选中的头像
			motivational_text:'',          // 登录栏随机文字
			exam_score_count:'',           // 考试次数
			exam_score_sum:'',             // 考试总分
			exam_score_ave:0,              // 考试平均分
			task_count:'',                 // 作业次数
			task_rate_sum:'',              // 作业总分
			task_rate_ave:0,               // 作业平均分
			jifen:0,                       // 积分
			ex:0,                          // 经验
			avatar_list:[],                // 从商城购买的头像
			banner:require('../assets/image/banner1.mp4'),
			currentPage:1,                 // 头像
			page_size:9,                  
			total:1,              
        }
    },
	computed:{
		// 等级
		lv(){
			return Math.floor(this.ex / 100)
		},
		// 经验取余
		ex_percent(){
			return Math.floor(this.ex % 100)
		}
	},
	methods:{
		// 头像列表下滑无限加载效果
		load(){
			this.currentPage ++
			this.avatar_get()
		},
		// 修改密码
		mod_password(){
			this.$MessageBox.prompt('请输入新密码', '修改密码', {
			confirmButtonText: '确定',
			cancelButtonText: '取消',
			inputPattern: /^[a-zA-Z0-9_*]{6,18}$/,
			inputErrorMessage: '密码只能输入中英文和数字,以及_*,6-18位'
			}).then(({ value }) => {
				let params = {
					password: value,
				}
				axios({
                method:'get',
                url:'https://xiaolily.cn/Password_mod.php',
                params:params
				}).then(
					response => {
						console.log(response)
						if (response.data.error == 0){
							this.$message({
								message: response.data.msg,
								type: 'success'
							})
						}
						else{
							this.$message.error(response.data.msg);
						}
					},
					error => {
						console.log('请求失败了',error.message)
					}
				)
			})
		},
		// 注销登录
		Logout(){
			axios.get('https://xiaolily.cn/Logout.php').then(
				response => {
					console.log(response)
					this.$notify({
						title: '成功',
						message: '注销成功,将于一秒后退出',
						type: 'success',
						duration:1000,
					});
					// 提醒：登录栏是否显示的条件为vuex中是否存在username
					this.$store.state.username = ''
				},
				error => {
					console.log('请求失败了',error.message)
				}
			)
		},
		// 选择并修改头像
		choose_avatar() {
			// 以/分割，从3开始取（包括3），里/连接
			let avatar_relative = this.avatar.split("/").slice(3).join('/')
			// 如果头像不为空，用户至少选择了一个头像
			if(this.avatar){
				let params = {
                	avatar: avatar_relative,
				}
				axios({
					method:'get',
					url:'https://xiaolily.cn/Avatar_mod.php',
					params:params
				}).then(
					response => {
						// console.log(response)
						if (response.data.error == 0){
							this.$message({
								message: response.data.msg,
								type: 'success',
								duration:1000
							})
							// 正常情况要重新注销登录才能更新vuex头像信息，这里直接修改vuex为新头像
							// this.$store.state.avatar = this.avatar
							this.$store.commit('MOD_AVATAR',this.avatar)
						}
						else{
							this.$message.error(response.data.msg);
						}
					},
					error => {
						console.log('请求失败了',error.message)
					}
				)
			}
		},
		// 手动切换滤镜
		before_img(){
			this.random_number -= 1
			if(this.random_number == 0){
				this.random_number = 2
			}
			this.filter_dot ='filter_dot' + this.random_number
		},
		next_img(){
			this.random_number += 1
			if(this.random_number == 3){
				this.random_number = 1
			}
			this.filter_dot ='filter_dot' + this.random_number
		},
		// 随机背景图
		random_banner(){
			let randomNumber = Math.floor(Math.random() * 3) + 1;
			this.banner = require(`../assets/image/banner${randomNumber}.mp4`)
		},
		/* 首页下拉箭头、因为模板中无window，所以用我自己的土办法加个window */
		headertop_down() {
			window.headertop_down()
		},
		//登陆栏随机文字
		random_text(){
			var butong_net = new Array('学习编程使人快乐','天道酬勤','穷且益坚，不坠青云之志','每天编程一小时，幸福生活一辈子','盛年不重来，一日难再晨。及时当勉励，岁月不待人'); 
			var butong_net2 = Math.floor(Math.random() * butong_net.length); 
			this.motivational_text = butong_net[butong_net2];
		},
		// 自动打字机实例化并挂载元素
		mount_Typed(){
			new window.Typed('.element', {
				strings: ["如需教师端账号，请钉钉联系信息部彭韦浩","作业、教案、考试，轻松管理，教师的省心小助手","如需教师端账号，请钉钉联系信息部彭韦浩"], //输入内容, 支持html标签
				typeSpeed: 200, //打字速度
				backSpeed: 100, //回退速度
				loop: true,//是否循环
				loopCount: Infinity,
				showCursor: true//是否开启光标
			});
		},
		// 获取统计数据
		statistic_get(){
			axios({
				method:'get',
				url:'https://xiaolily.cn/Statistic_get.php',
			}).then(
				response => {
					// console.log(response)
					// 考试成绩
					this.exam_score_count = response.data.exam_score_count
					this.exam_score_sum = response.data.exam_score_sum
					this.exam_score_ave = response.data.exam_score_ave.toFixed(2)
					// 作业评价
					this.task_count = response.data.task_count
					this.task_rate_sum = response.data.task_rate_sum
					this.task_rate_ave = response.data.task_rate_ave
					// 积分&经验
					this.jifen = response.data.jifen
					this.ex = response.data.ex
				},
				error => {
					console.log('请求失败了',error.message)
			})
		},
		// 获取头像
		avatar_get(){
			// console.log(this.currentPage)
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
            }
			axios({
				method:'get',
				url:'https://xiaolily.cn/Shop_avatar_get.php',
				params:params
			}).then(
				response => {
					// console.log(response)
					this.total = parseInt(response.data.total)
					let avatar_list = []
					for(let i in response.data.data){
						let file = ''
						file = 'https://xiaolily.cn/' + 
						response.data.data[i].file_dir + 
						'/' + response.data.data[i].file_name
						avatar_list.push(file)
					}
					this.avatar_list = avatar_list
					// console.log(this.avatar_list)
				},
				error => {
					console.log('请求失败了',error.message)
			})
		},
		// 改变每页显示数量事件
		handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.avatar_get()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.avatar_get()
        },
		// 维护中禁止其他用户使用
		weihu_check(){
			axios({
				method:'get',
				url:'https://xiaolily.cn/conn.php',
			}).then(
				response => {
					// console.log(response)
					if(response.data.error == 1){
						this.$message({
							type:'warning',
							message:response.data.msg,
							duration:5000
                		})
					}

				},
				error => {
					console.log('请求失败了',error.message)
			})
		}
	},
	mounted(){
		this.mount_Typed()
		this.random_text()
		this.statistic_get()
		this.random_banner()
		this.weihu_check()
	},
	created(){
		this.avatar_get()
	}
}
</script>

<style scoped>
header{
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow:hidden
}
#banner{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -2;
    /* 元素的内容应该如何去适应指定容器的高度与宽度，设置为自适应充满 */
    object-fit:fill;
}

#header_center{
    position: absolute;
    top: 20vh;
	left: 25vw;
    width: 50vw;
    height: 40vh;
}

.center_text {
	width: 100%;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 90px;
    /* 文字居中 */
    text-align: center;
    /* 文字转大写 */
    text-transform: uppercase;

}

/* 设置文字不换行，超过的部分用“...”代替 */
.no_wrap{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* 首页一言打字区域 */
.header-info {
    width: 60%;
    font-size: 16px;
    color: #EAEADF;
    background: rgba(0, 0, 0, .5);
    padding: 15px;
	margin-top: 5vh;
    letter-spacing: 0;
    line-height: 30px;
    border-radius: 30px; 
    white-space: nowrap;
    text-align: center;
}

/* 侧边登录信息 */
#header-right{
    position: absolute;
    top: 20vh;
	left: 75vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 21vw;
    height: 330px;
    overflow: hidden;
	background-color: rgba(255, 255, 255);
	border-radius: 10px;
}
.logged_username{
    font-size: 18px;
    margin-top: 20px;
	color: black;
}

.admin_name{
	background: rgb(217, 217, 217);
	padding: 5px;
	margin-top: 5px;
	color: #3e3e3e;
}

#motivational_text{
    margin: 30px 0;
    font-size: 14px;
    font-weight: 600;
    width: 80%;
    text-align: center;
    letter-spacing: 1px;
}

/* css滤镜切换 */
.arrow_section{
	margin-top:30px;
	width: 50%;
	justify-content: space-around;
}
.arrow{
	width: 20px;
	background-color: white;
	opacity: 0.5;
	border-radius: 50%;
	padding: 5px;
}
.filter_dot1{
	display: none;
}
.filter_dot2{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-image: url(../assets/image/dot.gif);
}
.filter_dot3{
    position: absolute;
	height: 100%;
    width: 100%;
	top: 0;left: 0;right: 0;bottom: 0;
	backdrop-filter: blur(3px);
	filter: blur(5px);
}

/* 首页标题特效 */
.glitch {
	position: relative;
	color: #fff;
	mix-blend-mode: lighten;
}
.glitch:after,
.glitch:before {
	content: attr(data-text);
	position: absolute;
	top: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0);
	clip: rect(0, 0, 0, 0)
}
.glitch:before {
	left: -1px;
	text-shadow: 1px 0 #ff3f1a
}
.glitch:after {
	left: 1px;
	text-shadow: -1px 0 #00a7e0
}
.glitch:hover:before {
	text-shadow: 4px 0 #ff3f1a;
	animation: glitch-loop-1 .8s infinite ease-in-out alternate-reverse
}
.glitch:hover:after {
	text-shadow: -5px 0 #00a7e0;
	animation: glitch-loop-2 .8s infinite ease-in-out alternate-reverse
}
@-webkit-keyframes glitch-loop-1 {
	0% {
		clip: rect(36px, 9999px, 9px, 0)
	}
	25% {
		clip: rect(25px, 9999px, 99px, 0)
	}
	50% {
		clip: rect(50px, 9999px, 102px, 0)
	}
	75% {
		clip: rect(30px, 9999px, 92px, 0)
	}
	100% {
		clip: rect(91px, 9999px, 98px, 0)
	}
}
@keyframes glitch-loop-1 {
	0% {
		clip: rect(36px, 9999px, 9px, 0)
	}
	25% {
		clip: rect(25px, 9999px, 99px, 0)
	}
	50% {
		clip: rect(50px, 9999px, 102px, 0)
	}
	75% {
		clip: rect(30px, 9999px, 92px, 0)
	}
	100% {
		clip: rect(91px, 9999px, 98px, 0)
	}
}
@-webkit-keyframes glitch-loop-2 {
	0% {
		top: -1px;
		left: 1px;
		clip: rect(65px, 9999px, 119px, 0)
	}
	25% {
		top: -6px;
		left: 4px;
		clip: rect(79px, 9999px, 19px, 0)
	}
	50% {
		top: -3px;
		left: 2px;
		clip: rect(68px, 9999px, 11px, 0)
	}
	75% {
		top: 0;
		left: -4px;
		clip: rect(95px, 9999px, 53px, 0)
	}
	100% {
		top: -1px;
		left: -1px;
		clip: rect(31px, 9999px, 149px, 0)
	}
}
@keyframes glitch-loop-2 {
	0% {
		top: -1px;
		left: 1px;
		clip: rect(65px, 9999px, 119px, 0)
	}
	25% {
		top: -6px;
		left: 4px;
		clip: rect(79px, 9999px, 19px, 0)
	}
	50% {
		top: -3px;
		left: 2px;
		clip: rect(68px, 9999px, 11px, 0)
	}
	75% {
		top: 0;
		left: -4px;
		clip: rect(95px, 9999px, 53px, 0)
	}
	100% {
		top: -1px;
		left: -1px;
		clip: rect(31px, 9999px, 149px, 0)
	}
}

/* 波浪线 */
#banner_wave_1 {
	width: auto;
	height: 50px;
	background: url(../assets/image/wave1.png) repeat-x;
	_filter: alpha(opacity=80);
	position: absolute;
	bottom: 0;
	width: 400%;
	left: -236px;
	z-index: 1;
	opacity: 1;
	transition-duration: .4s,.4s;
	animation-name: move2;
	animation-duration: 240s;
	animation-fill-mode: backwards;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
	opacity: 0.5;
}
#banner_wave_2 {
	width: auto;
	height: 70px;
	background: url(../assets/image/wave2.png) repeat-x;
	_filter: alpha(opacity=80);
	position: absolute;
	bottom: 0;
	width: 400%;
	left: 0;
	z-index: 0;
	opacity: 1;
	transition-duration: .4s,.4s;
	animation-name: move2;
	animation-duration: 160s;
	animation-fill-mode: backwards;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}
@keyframes move1 {
	100%{
		background-position: 100% 0;
	}
}
@keyframes move2 {
	100%{
		background-position: -100% 0;
	}
}

/* 首页下拉箭头 */
.headertop-bar::after {
    content: '';
    width: 150%;
    height: 4.375rem;
    background: #fff;
    left: -25%;
    bottom: -2.875rem;
    border-radius: 100%;
    position: absolute;
    z-index: 4;
}
.headertop-down {
    position: absolute;
    bottom: 100px;
    left: 50%;
    cursor: pointer;
    z-index: 5;
}
@media (max-width: 860px) {
    .headertop-down {
        display: none
    }
}
.headertop-down i {
    font-size: 28px;
    color: #fff;
    -ms-transform: scale(1.5,1); /* IE 9 */
    -webkit-transform: scale(1.5,1); /* Safari */
    transform: scale(1.5,1); /* Standard syntax */
}
.statistic_section{
	width: 100%;
	margin: 15px 0;
}
</style>

