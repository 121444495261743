<template>
<div class="section chinese_font">
    <el-collapse v-model="activeName" accordion>
        <div v-for="(user,index) in user_list" :key="user.id">
            <el-collapse-item :name="user.id" style="border-bottom: 1px solid gray;">
                <template slot="title">
                    <div class="flex_row">
                        <!-- 用户信息 -->
                        <!-- <img :src="require('../../assets/touxiang/'+user.avatar)" class="small_img"> -->
                        <img :src="user.avatar" class="small_img">
                        <span class="username">{{user.username}}</span>
                        <!-- 上传标签 -->
                        <span style="margin-right:15px">
                            <el-tag type="success" v-if="user.task_id">已上传</el-tag>
                            <el-tag type="warning" v-else>未上传</el-tag>
                        </span>
                        <!-- 上传代码 -->
                        <el-button @click="dialogVisible = true" 
                        v-if="user.username==$store.state.username"
                        size="mini" type="primary"
                        >上传代码</el-button>
                        <!-- 点赞 -->
                        <span v-if="user.task_id&&$store.state.username!=user.username" class="flex_row">
                            <img src="../../assets/image/good.png"
                            class="small_img like_img shake-button" 
                            :class="{ 'shake-animation': user.isShaking }" 
                            @click.stop="like(user.task_id,index,user.username)"
                            >   
                            <span>{{user.like_count}}</span>
                        </span>
                        <!-- 评分 -->
                        <div @click.stop="rate(user.task_id,user.rate,user.username)" 
                        v-if="user.task_id&&$store.state.admin>=1">
                            <el-rate
                                style="margin-left:30px"
                                v-model="user.rate"
                                show-text
                                :texts="['重做', '修改', '一般', '满意', '惊喜']"
                                :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
                            >
                            </el-rate>
                        </div>
                        <div v-if="user.task_id&&$store.state.admin<1">
                            <el-rate
                                disabled
                                style="margin-left:30px"
                                v-model="user.rate"
                                show-text
                                :texts="['重做', '修改', '一般', '满意', '惊喜']"
                                :colors="['#99A9BF', '#F7BA2A', '#FF9900']"
                                >
                            </el-rate>
                        </div>
                        <span @click="handleClick"
                        v-if="user.task_id" style="margin-left:20px;">
                            <el-input v-if="$store.state.admin>=1"
                            style="width:400px" 
                            v-model="user.comment" 
                            placeholder="教师批改意见"
                            @blur.stop="comment(user.task_id,user.comment)"
                            ></el-input>
                            <span v-else class="comment">批改意见:{{user.comment}}</span>
                        </span>
                    </div>
                </template>
                <!-- AI老师 -->
                <div class="flex_column"                     
                    v-loading="loading"
                    element-loading-text="AI老师正在评阅作业,请稍等"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)">
                    <div style="font-size:medium; min-height: 50px;padding: 20px;box-sizing: border-box;width: 80%;">
                        {{ user.AI_comment }}
                    </div>
                    <div v-if="user.task_id && !user.AI_comment && user.username==$store.state.username" class="flex_row" 
                    style="width: 100%;justify-content: space-around;margin-bottom: 15px;">
                        <div v-for="(index,i) in $route.query.AI_teacher_list" :key="index">
                            <el-button @click="AI_comment($route.query.AI_teacher_list[i],user.content,user.task_id)" size="mini" plain type="primary">
                                {{ $route.query.AI_teacher_list[i] }}点评
                                <i class="el-icon-user-solid"></i>
                            </el-button>
                        </div>
                    </div>
                </div>
                <!-- 作业内容 -->
                <!-- <div class="code-example" v-highlight>
                    <pre><code class="html" v-html="user.content"
                    style="font-size:20px"></code></pre>
                </div> -->
                <pre><code style="text-wrap: wrap;" class="code">{{ user.content }}</code></pre>
            </el-collapse-item>
        </div>
    </el-collapse>
    <!-- 上传代码弹出框 -->
    <el-dialog title="上传代码" :visible.sync="dialogVisible" width="30%">
        <div>
            <span>重新上传会覆盖之前代码：</span>
            <el-input type="textarea" :rows="14" v-model="user_input"></el-input>
        </div>
        <div slot="footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="upload">确定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios'
// import { Base64 } from "js-base64"; // 引入 js-base64 库
export default {
    name:'TaskDetail',
    data() {
        return {
            activeName: '1',
            user_list : [],
            task_list: [],
            dialogVisible:false,       // 代码上传输入框
            user_input:'',             // 用户提交的内容  
            AI_comment_content:'',
            loading:false, 
        }
    },
    methods:{
        // AI点评
        AI_comment(AI_teacher,task_content,task_id){
            this.$MessageBox.confirm('是否确定使用该AI老师', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.loading = true
                    let params = {
                        AI_teacher:AI_teacher,
                        timu_require:this.$route.query.timu_require,
                        task_content:task_content,
                        task_id:task_id,
                    }
                    axios({
                        method:'get',
                        url:'https://xiaolily.cn/AI_my_task.php',
                        params:params
                    }).then(
                        response => {
                            console.log(response)
                            if(response.data.error == 0){
                                this.getTaskList()
                            }else{
                                this.$message.error(response.data.msg);
                            }
                            // if(response.data.error == 1){
                            //     this.$message.error(response.data.msg);
                            // }else{
                            //     this.AI_comment_content = response.data.result
                            // }
                            this.loading = false
                        },
                        error => {
                            this.$message.error('请求失败了');
                            console.log('请求失败了',error.message)
                            this.loading = false
                        }
                    )
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消'
                    // });     
                    console.log('用户点击取消')     
            });
        },
        // 停止事件传播，教师评论的输入框点击之后会向上冒泡到这里来
        handleClick(event) {
            event.stopPropagation();
        },
        // 作业评价
        comment(task_id,comment){
            let params = {
                task_id: task_id,
                comment: comment,
            }
            // 发送请求
            axios({
                method:'get',
                url:'https://xiaolily.cn/Task_comment.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    this.getTaskList()
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 作业评分
        rate(task_id,rate,username){
            let params = {
                task_id: task_id,
                rate: rate,
                username: username
            }
            // 发送请求
            axios({
                method:'get',
                url:'https://xiaolily.cn/Task_rate.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    if(response.data.error==1){
                        this.$message({
                            message: response.data.msg,
                            type: 'success',
                            duration:1000
                        })
                    }
                    this.getTaskList()
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 点赞
        like(task_id,index,username) {
            // 登录验证
            if(!this.$store.state.user_id){
                this.$message({
                    message: '请登录后再操作',
                    type: 'warning'
                })
                return false
            }
            // 本地点赞数，超过15将被限制(清缓存就可以刷赞)
            // if(localStorage.getItem('local_like_count')>15){
            //     this.$message({
            //         message: '每人每天只能点十五个赞',
            //         type: 'warning',
            //         duration:1000
            //     })
            //     return false
            // }
            // localStorage.setItem('local_like_count',parseInt(localStorage.getItem('local_like_count')) +1)
            // 震动效果
            this.$set(this.user_list[index],'isShaking',false)
            this.user_list[index].isShaking = true // 在点击时将isShaking标记为true
            setTimeout(() => {
                 this.user_list[index].isShaking = false
            }, 1000); // 延迟500ms后再将isShaking标记为false
            let params = {
                task_id: task_id,
                username:username,
            }
            // 发送请求
            axios({
                method:'get',
                url:'https://xiaolily.cn/Task_like.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    if(response.data.error==0){
                        this.$message({
                            message: '点赞成功、对方积分+1,今日剩余点赞次数'+response.data.haved_like_num,
                            type: 'success',
                            duration:1000
                        })
                    }else{
                        this.$message({
                            message: '点赞失败，今日剩余次数不足',
                            type: 'error',
                            duration:1000
                        })
                    }
                    this.getTaskList()
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 获得班级学生名
        getUserList(){
             // 发送数据
            let params = {
                class_name: this.$route.query.class_name
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Users_class_get.php',
                params:params
            }).then(
                response => {
                    this.user_list = response.data.data
                    // console.log(this.user_list)
                    this.getTaskList()
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 上传代码
		upload(){
            // let contentEncode = Base64.encode(this.user_input)
            // let params = {
            //     task_id: this.$route.query.id,
            //     content: this.user_input
            // }
            let data = {
                task_id: this.$route.query.id,
                content: this.user_input
            }
            axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method:'post',
                url:'https://xiaolily.cn/Task_add.php',
                data:data
            }).then(
                response => {
                    console.log(response)
                    if (response.data.error == 0){
                        this.$message({
                            message: response.data.msg,
                            type: 'success'
                        })
                        this.dialogVisible = false
                        this.getTaskList()
                    }
                    else{
                        this.$message.error(response.data.msg);
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
		},
        // 获得所有作业
        getTaskList(){
             // 发送数据
            let params = {
                task_id: this.$route.query.id,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Task_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    this.task_list = response.data.data
                    // 将作业更新到user_list中
                    for (let j in this.user_list){
                        for (let i in this.task_list){
                            if (this.user_list[j].username == this.task_list[i].username){
                                // this.$set(this.user_list[j],'content',Base64.decode(this.task_list[i].content))
                                this.$set(this.user_list[j],'content',this.task_list[i].content.replace(/</g, "\<"))
                                this.$set(this.user_list[j],'like_count',this.task_list[i].like_count)
                                this.$set(this.user_list[j],'task_id',this.task_list[i].id)
                                this.$set(this.user_list[j],'rate',parseInt(this.task_list[i].rate))
                                this.$set(this.user_list[j],'comment',this.task_list[i].comment)
                                this.$set(this.user_list[j],'AI_comment',this.task_list[i].AI_comment)
                                break
                            }
                        }
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 获得AI老师
        get_AI_teacher(){}
    },
    mounted(){
        this.getUserList()
        if(localStorage.getItem('local_like_count')==null){
            localStorage.setItem('local_like_count', 0)
        }
    }
}
</script>

<style scoped>
/* 可以复制文本 */
.code{
  -moz-user-select: all;
  -o-user-select: all;
  -khtml-user-select: all;
  -webkit-user-select: all;
  -ms-user-select: all;
  user-select: all;
}
.section{
    width: 70vw;
    margin-left: 15vw;
    margin-top: 20vh;
    padding: 20px;
    background-color: white;
}
.el-collapse-item__header{
  font-size: 20px !important;
}
.like_img{
    margin-left: 20px;
}
.comment{
    color: gray;
    font-size: smaller;
}
.username{
    margin-right:20px;
    font-size:large;
}

/* 震动效果 */
.shake-button {
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}
.shake-animation {
  animation-name: shake;
}
@keyframes shake {
  0% {
    transform: translate(-5px, -5px);
  }
  10% {
    transform: translate(5px, 5px);
  }
  20% {
    transform: translate(-5px, -5px);
  }
  30% {
    transform: translate(5px, 5px);
  }
  40% {
    transform: translate(-5px, -5px);
  }
  50% {
    transform: translate(5px, 5px);
  }
  60% {
    transform: translate(-5px, -5px);
  }
  70% {
    transform: translate(5px, 5px);
  }
  80% {
    transform: translate(-5px, -5px);
  }
  90% {
    transform: translate(5px, 5px);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>