<template>
<div>
    <!-- <iframe 
    class="bg" title="myIframe" src="sakura.html" 
    marginwidth="0" marginheight="0">
    </iframe> -->
    <div class="section flex_row">
        <!-- 公共文件 -->
        <div class="right_section">
            <div class="upload_section">
                <div class="title">公共文件</div>
                <el-upload 
                class="upload-demo"
                drag
                action="https://xiaolily.cn/File_add_public.php"
                multiple
                name="public_file"
                :on-success="upload"
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处,或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传zip/rar文件,且不超过50MB</div>
                </el-upload>
            </div>
            <div class="download_section chinese_font">
                <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <div v-for="(fileUrl,index) in fileUrl_list_public" :key="index" class="infinite-list-item">
                        <a 
                            style="color:rgb(84, 84, 84);" 
                            :href="'https://xiaolily.cn/'+fileUrl.file_dir+'/'+fileUrl.file_name">
                            {{fileUrl.file_name}}
                        </a>
                        <!-- <a 
                            style="color:rgb(84, 84, 84);" 
                            @click="download(url=fileUrl.file_dir+'/'+fileUrl.file_name,
                            file_name=fileUrl.file_name)"
                            href="#">
                            {{fileUrl.file_name}}
                        </a> -->
                        <!-- 删除 -->
                        <el-button
                            @click="del(fileUrl.id,fileUrl.file_dir,fileUrl.file_name)"
                            v-if="$store.state.admin>=2"
                            type="primary" icon="el-icon-delete" size="mini"
                        ></el-button>
                    </div>
                </ul>
            </div>
        </div>
        <!-- 个人网盘 -->
        <div class="right_section">
            <div class="upload_section">
                <div class="title">个人网盘</div>
                <el-upload 
                class="upload-demo"
                drag
                action="https://xiaolily.cn/File_add_private.php"
                multiple
                name="private_file"
                :on-success="upload"
                >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处,或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传zip/rar文件,且不超过50MB</div>
                </el-upload>
            </div>
            <div class="download_section chinese_font">
                <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                    <div v-for="(fileUrl,index) in fileUrl_list_private" :key="index" class="infinite-list-item">
                        <a 
                            style="color:rgb(84, 84, 84);" 
                            :href="'https://xiaolily.cn/'+fileUrl.file_dir+'/'+fileUrl.file_name">
                            {{fileUrl.file_name}}
                        </a>
                        <!-- <a 
                            style="color:rgb(84, 84, 84);" 
                            @click="download(url=fileUrl.file_dir+'/'+fileUrl.file_name,
                            file_name=fileUrl.file_name)"
                            href="#">
                            {{fileUrl.file_name}}
                        </a> -->
                        <!-- 删除 -->
                        <el-button
                            @click="del(fileUrl.id,fileUrl.file_dir,fileUrl.file_name)"
                            v-if="$store.state.admin>=2"
                            type="primary" icon="el-icon-delete" size="mini"
                        ></el-button>
                    </div>
                </ul>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:"MyFile",
    data() {
      return {
        fileUrl_list_private:[],
        fileUrl_list_public:[]
      }
    },
    methods:{
        upload(response, file, fileList){
            console.log(response)
            if(response.error==0){
                this.$message({
                    type:'success',
                    message:response.msg
                })
                this.file_get()
            }else{
                this.$message({
                    type:'warning',
                    message:response.msg
                })
            }
        },
        file_get(){
            axios.post('https://xiaolily.cn/File_get.php').then(
                response => {
                    this.fileUrl_list_private = []
                    this.fileUrl_list_public = []
                    // 把私有文件和公共文件分开
                    for (let i of response.data.data){
                        if(i.type == 'private'){
                            this.fileUrl_list_private.push(i)
                        }else{
                            this.fileUrl_list_public.push(i)
                        }
                    } 
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 下载文件
        download(url,file_name){
            let params = {
                url: url,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Download.php',
                params:params,
                responseType: 'blob' // 指示axios将响应作为blob处理  
            }).then(
                response => {
                    console.log(response)
                    // 创建一个blob对象，并使用URL.createObjectURL()创建一个指向它的URL  
                    const url = window.URL.createObjectURL(new Blob([response.data]));  
                    
                    // 创建一个a标签用于下载  
                    const link = document.createElement('a');  
                    link.href = url;  
                    link.setAttribute('download', file_name); // 设置下载文件的名称  
                    
                    // 触发下载  
                    document.body.appendChild(link);  
                    link.click();  
                    
                    // 释放URL对象  
                    window.URL.revokeObjectURL(url);  
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        del(id,file_dir,file_name){
            const file_url = file_dir+'/'+file_name
            let params = {
                dataSheet: 'files',
                id: id,
                file_url: file_url,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Delete.php',
                params:params
            }).then(
                response => {
                    console.log(response)
                    this.file_get()
                    // 删除成功提示
                    this.$message({
                        type: 'success',
                        message: response.data.msg
                    });
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        load(){
            console.log('滑动到底部')
        }
    },
    mounted(){
        this.file_get()
    }
}
</script>

<style scoped>
a:hover{
    color: #ff0000 !important;
}
.bg{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
.section{
    margin-top: 12vh;
    margin-left: 15vw;
    min-height: 75vh;
    width: 70vw;
    padding: 20px;
    border-radius: 5px;
    background-color: rgba(255,255,255,0.5);
    align-items: flex-start;
}
.download_section{
    width: 90%;
}
.right_section{
    width: 50%;
    height: 100%;
    padding: 0 20px;
}
.title{
    margin-bottom: 20px;
    font-size: larger;
}
.infinite-list{
    max-height: 40vh;
    width: 100%;
}
.infinite-list-item{
    height: auto;
    margin: 5px;
    padding: 5px;
    color: #efefef !important;
    background-color: white;
    word-break:break-all;
}
</style>