<template>
<div>
    <!-- 表格+页码 -->
    <div class="flex_column">
    <!-- 表格 -->
    <div class="table_section">
        <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
        <el-table
            border
            stripe 
            height='70vh'
            :data="exam_list"
            :default-sort = "{prop: 'date', order: 'descending'}"
        >
        <el-table-column
            prop="id"
            label="序号"
            sortable
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            prop="user_name"
            label="姓名"
            sortable
            width="180">
        </el-table-column>
        <el-table-column
            prop="class_name"
            label="班级"
            width="180"
            sortable>
        </el-table-column>
        <el-table-column
            prop="score"
            label="分数"
            width="100"
            sortable
            align="center">
        </el-table-column>
        <el-table-column
            prop="time"
            label="提交时间"
            width="160"
            sortable
            align="center">
        </el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作">
            <!-- getUserInfo不需要传参,只是因为scope如果不用就会报错 -->
            <template slot="header" slot-scope="scope">
                <el-button
                    size="mini"
                    type="primary" plain
                    @click="to_excel(scope)"
                    >导出
                </el-button>
            </template>
            <template slot-scope="scope">
                <router-link v-if="$store.state.admin>=2"
                    :to="'/Exam_index/Exam_look?exam_id='+scope.row.exam_id
                    +'&user_id='+scope.row.user_id"
                >
                    <el-button
                        size="mini"
                        type="primary" plain
                        >查看
                    </el-button>
                </router-link>
                <el-button v-else
                        size="mini"
                        type="primary" plain
                        disabled
                        >查看
                </el-button>
            </template>
        </el-table-column>
        </el-table>
    </div>
        <!-- 分页 -->
        <div style="margin:2vh 0">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
</div> 
</template>
        
<script>
import axios from 'axios'
import Exam_add from './Exam_add.vue'
export default {
    name:"ExamScore",
    components:{Exam_add}, 
    data() {
        return {
            currentPage: 1,       // 当前的页码
            page_size: 50,        // 每页显示条数
            exam_list:[{},{}],    // 试卷列表
            total:1,              // 题库列表总长度
        }
    },
    methods: {
        // 对象数组转二维数组
        convertToCSV(data){
            let csv = [];  
            data.forEach(item => {  
                let row = Object.keys(item).map(key => `"${key}","${item[key]}"`).join(",");  
                csv.push(row);  
            });  
            return csv.join("\r\n");  
        },
        // 将对象转换为CSV格式  
        to_excel(){     
            // 调用函数，并将结果写入Blob对象，然后下载文件  
            let csvData = this.convertToCSV(this.exam_list);  
            let csvBlob = new Blob([csvData], {type: 'text/csv'});  
            let url = window.URL.createObjectURL(csvBlob);  
            let link = document.createElement('a');  
            link.href = url;  
            link.download = '考试成绩.csv'; // 可以设置任何文件名  
            document.body.appendChild(link);  
            link.click();  
            document.body.removeChild(link);
        },
        // 删除考试
        Delete(index, row){
            console.log(index, row);
            this.$MessageBox.confirm('此操作将永久删除该题库, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 发送请求
                let params = {
                    dataSheet: 'exam_list',
                    id: row.id,
                }
                axios({
                    method:'get',
                    url:'https://xiaolily.cn/Delete.php',
                    params:params
                }).then(
                response => {
                    console.log('删除',response)
                    this.getExamList()
                    // 删除成功提示
                    this.$message({
                    type: 'success',
                    message: response.data.msg
                    });
                },
                error => {
                    console.log('请求失败了',error.message)
                })
            })
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getExamList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getExamList()
        },
        // 分页获取分数列表
        getExamList(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
                exam_id: this.$route.query.exam_id,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Exam_paper_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    this.exam_list = response.data.data
                    this.total = parseInt(response.data.total)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
    },
    mounted(){
        this.getExamList()
    }
}
</script>

<style scoped>
.table_section{
    width: 80%;
    margin-left: 10%;
    margin-top: 3vh;
}
</style>