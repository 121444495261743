import Vue from 'vue';
import hljs from 'highlight.js';
import 'highlight.js/styles/default.css'; // 选择合适的样式

Vue.directive('highlight', {
  inserted(el) {
    const blocks = el.querySelectorAll('pre code');
    for (const block of blocks) {
      hljs.highlightBlock(block);
    }
  },
  componentUpdated(el) {
    const blocks = el.querySelectorAll('pre code');
    for (const block of blocks) {
      hljs.highlightBlock(block);
    }
  },
});