<template>
<div>
  <!-- 表格+页码 -->
  <div class="flex_column">
    <!-- 表格 -->
    <div class="table_section">
      <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
      <el-table
          border
          stripe 
          height='70vh'
          :data="exam_list"
          :default-sort = "{prop: 'date', order: 'descending'}"
      >
      <el-table-column
          prop="id"
          label="序号"
          sortable
          width="80"
          align="center">
      </el-table-column>
      <el-table-column
          prop="exam_name"
          label="试卷名"
          sortable
          width="200">
      </el-table-column>
      <el-table-column
          prop="timu_count"
          label="试题数量"
          width="110"
          sortable
          align="center">
      </el-table-column>
      <el-table-column
          prop="choosed_course"
          label="课程"
          width="120"
          sortable
          align="center">
      </el-table-column>
      <el-table-column
          prop="choosed_store"
          label="题库范围"
          sortable
          width="220"
          align="center">
          <template slot-scope="scope">
            <div class="flex_row" style="flex-wrap: wrap;">
              <div v-for="(store,index) in scope.row.choosed_store" :key="index">
                <el-tag style="margin-bottom: 3px;">{{store}}</el-tag>
              </div>
            </div>
          </template>
      </el-table-column>
      <!-- 参考班级 -->
      <el-table-column
          prop="choosed_class"
          label="参考班级"
          width="120"
          sortable
          align="center">
      </el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作">
            <!-- 新增试卷（操作权限2） -->
            <template slot="header" slot-scope="scope">
                <el-button type="primary" size="small"
                @click="addExam(scope)"
                v-if="$store.state.admin>=2">新增试卷</el-button>
            </template>
            <template slot-scope="scope">
                <!-- 开始考试（需要传递题库范围和试题数量，才能从题库里抽题）（操作权限：已登录） -->
                <router-link 
                    :to="'/Exam_index/Exam_exam?choosed_store='+scope.row.choosed_store
                    +'&timu_count='+scope.row.timu_count+'&exam_id='+scope.row.id+
                    '&class='+scope.row.choosed_class"
                    v-if="$store.state.class_name==scope.row.choosed_class"
                >
                    <el-button type="primary" plain size="mini"
                    style="margin-right: 10px;">开始考试
                    </el-button>
                </router-link>
                <el-button type="primary" plain size="mini" disabled v-else
                style="margin-right: 10px;">开始考试
                </el-button>
                <!-- 分数统计(无权限) -->
                <router-link :to="'/Exam_index/Exam_score?exam_id='+scope.row.id">
                    <el-button type="success" plain size="mini"
                    style="margin-right: 10px;">分数统计
                    </el-button>
                </router-link>
                <!-- 删除题库(操作权限2) -->
                <el-button
                    size="mini"
                    type="danger" plain
                    v-if="$store.state.admin>=2"
                    @click="Delete(scope.$index, scope.row)">删除
                </el-button>
                <el-button
                    size="mini"
                    type="danger" plain
                    v-else
                    disabled>删除
                </el-button>
            </template>
        </el-table-column>
        </el-table>
    </div>
      <!-- 分页 -->
      <div style="margin:2vh 0">
          <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-sizes="[25, 50, 75, 100]"
          :page-size="50"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
          </el-pagination>
      </div>
  </div>
  <!-- 新增试卷 -->
  <el-dialog
      title="新增试卷"
      :visible.sync="addExamVisible"
      width="50%"
      top=5vh
      :before-close="handleClose">
          <Exam_add :getExamList="getExamList" :handleClose="handleClose"></Exam_add>
  </el-dialog>
</div> 
</template>
      
<script>
import axios from 'axios'
import Exam_add from './Exam_add.vue'
import store from '@/store'
export default {
    name:"MyStore",
    components:{Exam_add}, 
    data() {
        return {
            currentPage: 1,       // 当前的页码
            page_size: 50,        // 每页显示条数
            exam_list:[{},{}],    // 试卷列表
            total:1,              // 题库列表总长度
            addExamVisible:false  // 新增试卷弹出层
        }
    },
    methods: {
        // 新增考试
        addExam(scope){
            this.addExamVisible = true
        },
        // 关闭弹出框
        handleClose() {
            this.addExamVisible = false
        },
        // 删除考试
        Delete(index, row){
            console.log(index, row);
            this.$MessageBox.confirm('此操作将永久删除该试卷, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 发送请求
                let params = {
                    dataSheet: 'exam_list',
                    id: row.id,
                }
                axios({
                    method:'get',
                    url:'https://xiaolily.cn/Delete.php',
                    params:params
                }).then(
                response => {
                    console.log('删除',response)
                    this.getExamList()
                    // 删除成功提示
                    this.$message({
                    type: 'success',
                    message: response.data.msg
                    });
                },
                error => {
                    console.log('请求失败了',error.message)
                })
            })
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getExamList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getExamList()
        },
        // 分页获取考试列表
        getExamList(){
            // 发送数据
            let params = {
              currentPage: this.currentPage,
              page_size: this.page_size,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Exam_list_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                  // 因为用户选择的题库是一个字符串数组、所以要用正则拆开为数组，依次处理数据结构
                  for(let i in response.data.data){
                    response.data.data[i].choosed_store = response.data.data[i].choosed_store.match(/"(.{1,20}?)"/g)
                  }
                  this.exam_list = response.data.data
                  this.total = parseInt(response.data.total)  // element分页必需接受整数类型
                //   console.log('考试列表：',this.exam_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
    },
    mounted(){
        this.getExamList()
    }
}
</script>

<style scoped>
.table_section{
    width: 80%;
    margin-left: 10%;
    margin-top: 3vh;
}
</style>