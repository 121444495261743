import Vue from 'vue'
import App from './App.vue'
//引入并使用VueRouter
import VueRouter from 'vue-router'
Vue.use(VueRouter)
//引入路由器（./router/index中的index可以省略）
import router from './router'
//引入store
import store from './store'
//引入转盘
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
//关闭Vue的生产提示
Vue.config.productionTip = false
//关闭Vue的黄色警告
// Vue.config.silent = true;
//引入代码高亮
import './directives/highlight';
// 引入无限加载
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

// 按需引入饿了么UI
import {
  Button, Row, Dialog, Select, Option, Input, Rate, Form, Notification,
  FormItem, Radio, RadioGroup, Switch, DatePicker, Popover, Avatar, Table, TableColumn,
  Checkbox, CheckboxGroup, Divider, Card, Message, MessageBox, Alert, Pagination,
  PageHeader, RadioButton, Tag, Carousel, CarouselItem, Collapse, CollapseItem,
  Statistic, Progress, Upload, Tooltip, Breadcrumb, BreadcrumbItem, Slider, Col,
  Loading
} from 'element-ui';
Vue.use(Button)            //按钮
Vue.use(Dialog)            //弹出层
Vue.use(Row)
Vue.use(Select)            //下拉选择框
Vue.use(Option)            //下拉选择框
Vue.use(Input)             //输入框
Vue.use(Rate)              //Rate评分
Vue.use(FormItem)          //表单
Vue.use(Form)
Vue.use(RadioGroup)        //单选组 
Vue.use(Radio)             //单选     
Vue.use(Switch)            //Switch开关            
Vue.use(DatePicker)        //日期选择器            
Vue.use(Checkbox)          //多选框           
Vue.use(CheckboxGroup)     //多选框组           
Vue.use(Divider)           //分割线           
Vue.use(Card)              //卡片  
Vue.use(Alert)             //警告  
Vue.use(Popover)           //弹出框  
Vue.use(Avatar)            //头像  
Vue.use(Table)             //表格  
Vue.use(TableColumn)       //表格  
Vue.use(Pagination)        //分页  
Vue.use(PageHeader)        //页头  
Vue.use(RadioButton)       //按钮样式的单选框组  
Vue.use(Tag)               //标签  
Vue.use(Carousel)          //轮播图  
Vue.use(CarouselItem)      //轮播图  
Vue.use(Collapse)          //折叠面板 
Vue.use(CollapseItem)      //折叠面板 
Vue.use(Statistic)         //统计数据
Vue.use(Progress)
Vue.use(Upload)
Vue.use(Tooltip)           //文字提示   
Vue.use(Breadcrumb)        //面包屑   
Vue.use(BreadcrumbItem)
Vue.use(Slider)
Vue.use(Col)               //布局
Vue.use(Loading)               //布局

//弹框,四个全局方法的调用方法依次为：MessageBox, MessageBox.alert, MessageBox.confirm 和 MessageBox.prompt 
Vue.prototype.$MessageBox = MessageBox;
Vue.prototype.$message = Message;         //消息提示，如果使用Vue.use(Message)，刷新页面会自动执行一次
Vue.prototype.$notify = Notification;     //通知

new Vue({
  render: h => h(App),
  router: router,
  store,             // 简写形式
}).$mount('#app')
