<template>
<div>
    <!-- 表格 -->
    <div class="section chinese_font" style="padding: 20px;">
        <!-- 页头 -->
        <div class="flex_row" style="justify-content: flex-start;">
            <div class="flex_row" style="width: 25vw;justify-content: flex-start;">
                <el-page-header style="margin-bottom: 20px;"
                @back="goBack" :content="'成绩：'+score+'分'"></el-page-header>           
            </div>
            <div class="flex_row" style="width: 75vw;">
                <el-radio-group v-model="type">
                    <el-radio-button label="1" :disabled="type!=1">选择题</el-radio-button>
                    <el-radio-button label="2" :disabled="type!=2">填空题</el-radio-button>
                    <el-radio-button label="3" disabled>编程题</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <!-- 主体 -->
        <div class="flex_row" style="align-items: flex-start;">
            <!-- 左区域 -->
            <div class="left_section infinite-list flex_column" style="overflow:auto">
                <div class="flex_row timu_list">
                    <div 
                    :class="current_timu==index?'timu_box_active':'timu_box'" 
                    @click="choose_timu(index)"
                    v-for="(timu,index) in timu_list" :key="index">{{index+1}}
                    </div>
                </div>
            </div>
            <!-- 右区域(infinite-list：element的无限滚动样式) -->
            <div class="right_section infinite-list flex_column" style="overflow:auto">
                <!-- 标题 -->
                <el-input
                    style="margin-bottom: 20px;font-size: 20px;"
                    type="textarea"
                    maxlength="500"
                    show-word-limit
                    :autosize="{ minRows: 4, maxRows: 7}"
                    placeholder="请输入标题"
                    v-model="title">
                </el-input>
                <!-- 选择题 -->
                <div v-show="type==1">
                    <el-input 
                        v-if="A"
                        placeholder="请输入内容" 
                        v-model="A" 
                        style="margin-bottom: 20px;"
                    >
                        <template slot="prepend">A</template>
                    </el-input>
                    <el-input 
                        v-if="B"
                        placeholder="请输入内容" 
                        v-model="B" 
                        style="margin-bottom: 20px;"
                    >
                        <template slot="prepend">B</template>
                    </el-input>
                    <el-input 
                        v-if="C" 
                        placeholder="请输入内容" 
                        v-model="C" 
                        style="margin-bottom: 20px;"
                    >
                        <template slot="prepend">C</template>
                    </el-input>
                    <el-input 
                        v-if="D"
                        placeholder="请输入内容" 
                        v-model="D" 
                        style="margin-bottom: 20px;"
                    >
                        <template slot="prepend">D</template>
                    </el-input>
                    <!-- 用户的答案 -->
                    <div class="flex_row">
                        <span v-if="is_submit">
                            <el-radio-group v-model="choose_input" disabled>
                                <el-radio-button label="A" v-if="A"></el-radio-button>
                                <el-radio-button label="B" v-if="B"></el-radio-button>
                                <el-radio-button label="C" v-if="C"></el-radio-button>
                                <el-radio-button label="D" v-if="D"></el-radio-button>
                            </el-radio-group>
                        </span>
                        <span v-else>
                            <el-radio-group v-model="choose_input">
                                <el-radio-button label="A" v-if="A"></el-radio-button>
                                <el-radio-button label="B" v-if="B"></el-radio-button>
                                <el-radio-button label="C" v-if="C"></el-radio-button>
                                <el-radio-button label="D" v-if="D"></el-radio-button>
                            </el-radio-group>
                        </span>
                    </div>
                </div>
                <!-- 填空题 -->
                <div v-show="type==2">
                    <el-input v-model="filling_input" placeholder="请输入正确答案"></el-input>
                </div>
                <!-- 答题按钮及正确答案 -->
                <div class="flex_row" style="margin-top: 20px;">
                    <!-- 答题按钮 -->
                    <div v-if="is_submit">
                        <el-button disabled
                        type="info">已作答</el-button>
                    </div>
                    <div v-else>
                        <el-button @click="submit" 
                        type="primary" plain>答题</el-button>
                    </div>
                    <!-- 显示正确答案 -->
                    <span v-if="is_submit">
                        <!-- 字符串a是否在数组b中，b.includes(a) -->
                        <!-- 选择题 -->
                        <div v-if="type==1">
                            <i v-show="correct.includes(choose_input)" class="el-icon-check right_icon"></i>
                            <span v-show="!correct.includes(choose_input)" class="flex_row">
                                <i class="el-icon-close error_icon"></i>
                                <span>正确答案：{{ correct[0] }}</span>
                            </span>  
                        </div>
                        <div v-if="type==2">
                            <i v-show="correct.includes(filling_input)" class="el-icon-check right_icon"></i>
                            <span v-show="!correct.includes(filling_input)" class="flex_row">
                                <i class="el-icon-close error_icon"></i>
                                <span>正确答案：{{ correct.join('、') }}</span>
                            </span>  
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import { Base64 } from "js-base64"; // 引入 js-base64 库
export default {
    name:'ExamExam',
    data() {
        return { 
            timu_list:[
                {title:'',A:'',B:'',C:'',D:'',correct:'',
                choose_input:'',filling_input:'',score:''}
            ],
            current_timu:0,      //当前选中的题目
            title:'',
            A:'',
            B:'',
            C:'',
            D:'',
            timu_id:'',   
            correct:[],                //正确选项
            choosed_store_list:[],     //该试卷的题库范围（其实这个应该定义为局部变量）
            is_submit:false,           //是否已提交过答卷
            per_score:0 ,              //每道题的分数
            score:0,                   //分数
            type:1,                    //题目类型
            filling: {                 //填空题
                answer1: '',
                answer2: '',
                answer3: '',
                answer4: ''
            },
            filling_input:'',          //用户输入的填空题答案     
            choose_input:'',           //用户输入的选择题答案    
            user_answer:'',            //用户输入的答案    
        }
    },
    methods: {
        // 获得题目列表
        getTimuList(){
            // 把前一页传过来的题库范围转为数组格式
            let choosed_store_list = this.$route.query.choosed_store.split(',')
            for (let i in choosed_store_list){
                this.choosed_store_list.push({store_name:choosed_store_list[i]})
            }
            // 发起请求
            let params = {
                store_name: JSON.stringify(this.choosed_store_list) , // 从前一个页面传过来的题库名称
                timu_count: this.$route.query.timu_count,
                exam_id: this.$route.query.exam_id,    // 从前一个页面传过来的试卷id
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Exam_timu_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    // 还未作答，请求到的是从题库随机抽取的题目
                    if(response.data.data[0].title){
                        this.timu_list = response.data.data
                        this.is_submit = false
                    // 已作答，是用户上次抽取的答卷，并且有作答记录
                    }else{
                        this.is_submit = true
                        // 如果已作答才更新分数
                        this.score = response.data.data[0].score
                        this.timu_list = response.data.data[0].store_paper
                        this.timu_list = JSON.parse(this.timu_list)
                    }
                    this.choose_timu(0)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 用户选择题目
        choose_timu(index){
            this.current_timu = index
            this.title = Base64.decode(this.timu_list[index].title)  // 解码
            this.A = this.timu_list[index].A
            this.B = this.timu_list[index].B
            this.C = this.timu_list[index].C
            this.D = this.timu_list[index].D
            this.is_submit = this.timu_list[index].is_submit
            this.timu_id = this.timu_list[index].id
            this.type = this.timu_list[index].type
            this.filling =JSON.parse(Base64.decode(this.timu_list[index].filling))
            // 如果是选择题
            if(this.type == 1){
                // this.correct = this.timu_list[index].correct
                this.correct = []
                this.correct.push(this.timu_list[index].correct)
                this.choose_input = this.timu_list[index].user_answer
            // 如果是填空题
            }else if(this.type == 2){
                // this.correct = this.filling.answer1
                this.correct = []
                for (let i in this.filling){
                    if(this.filling[i]!='')
                    this.correct.push(this.filling[i])
                }
                this.filling_input = this.timu_list[index].user_answer
            }
        },
        // 答题
        submit(event){
            // 如果是选择题
            if(this.type == 1){
                this.user_answer = this.choose_input
            // 如果是填空题
            }else if(this.type == 2){
                this.user_answer = this.filling_input
            }
            // 空校验
            if(!this.user_answer){
                this.$message({
                    type:'warning',
                    message:'前端:不准提交空内容'
                })
                event.preventDefault()
                return false
            }
            // 计算成绩(注意从数据库获取的数据都是字符串，哪怕数据库中定义的是int)
            // 填空题可以有多个答案，所以正确答案是个数组，只需有一个答案对的上即可
            for (let i in this.correct){
                if(this.correct[i] == this.user_answer){
                    this.score = Number(this.score)
                    this.score += this.per_score
                    this.$message({
                        type:'success',
                        message:`积分+${this.per_score}、经验+${this.per_score}`
                    })
                    break
                }
            }
            // 添加用户的作答
            this.timu_list[this.current_timu].user_answer = this.user_answer
            // 标记为已作答
            this.timu_list[this.current_timu].is_submit = true
            this.is_submit = true
            // 发送请求
            let data = {
                exam_id: this.$route.query.exam_id,    // 从前一个页面传过来的试卷id
                timu_list:JSON.stringify(this.timu_list),
                score:this.score,             // 分数
                per_score:this.per_score // 每题分数
            }
            axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method:'post',
                url:'https://xiaolily.cn/Exam_paper_add.php',
                data:data
            }).then(
                response => {
                    console.log(response)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        //返回上一层
        goBack() {
            this.$router.go(-1);
        },
        // 计算每道题的分数，百分制，平均计算，如果该试卷有10道题，则每道题10分
        compute_per_score(){
            this.per_score = 100/this.$route.query.timu_count
            this.per_score = this.per_score.toFixed(1); // 保留0位小数,toFixed()方法返回的结果是一个字符串类型值
            this.per_score = Number(this.per_score)
        },
        // 防止刷新
        prevent_refresh(){
            // 禁止右键菜单、防止用户点刷新
            document.addEventListener('contextmenu', function(e) {  
                e.preventDefault();  
            });
            // 禁止F5键刷新
            document.addEventListener('keydown', function(e) {  
                if (e.keyCode === 116) { // F5键的键码是116  
                    e.preventDefault();  
                }  
            });
        }
    },
    mounted(){
        this.getTimuList()
        this.compute_per_score()
        this.prevent_refresh()
    }
}
</script>

<style scoped>
.section{
    width: 75%;
    margin-left: 15%;
    margin-top: 3vh;
    background-color: white;
    height: 85vh;
}
.left_section{
    width: 25%;
    height: 65vh;
    border-right: 1px solid #c7c7c7;
    justify-content: flex-start;
}
.right_section{
    width: 75%;
    padding: 0 2vw;
}
.timu_list{
    flex-flow: wrap;
    justify-content: flex-start;
}
.timu_box{
    box-sizing: border-box;
    width: 40px;
    padding: 10px 12px;
    margin: 10px;
    border: 1px solid lightgray;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
}
.timu_box_active{
    box-sizing: border-box;
    width: 40px;
    padding: 10px 12px;
    margin: 10px;
    border: 1px solid #409EFF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
    text-align: center;
}
.add_timu{
    font-size: 32px;
}
.right_icon{
    font-size: 34px;
    margin-left: 20px;
    color: mediumseagreen;
}
.error_icon{
    font-size: 34px;
    margin-left: 20px;
    color: red;
}
</style>