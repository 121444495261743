<template>
<div>
    <div class="bread">
        <span class="chinese_font">
            <!-- 考试 -->
            <router-link to="/Exam_index/Exam" class="bread_text"
                active-class="active">考试
            </router-link>
            <span> | </span>
            <!-- 题库 -->
            <router-link to="/Exam_index/Store" class="bread_text"
                active-class="active">题库
            </router-link>
        </span>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
export default {
    name:'ExamIndex',
}
</script>

<style scoped>
.bread{
    text-align:center;
    margin-top: 12vh;
}
.bread_text{
    font-size: 25px;
}
.bread_text:hover{
    color: dimgray;
}
.active{
    color: #ff8000 !important;
}
.bg{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
</style>