<template>
<div>
    <!-- 线条背景(改变颜色在css/style.css) -->
    <!-- <iframe 
        class="bg" title="myIframe" src="/particles.js-master/demo/index.html" 
        marginwidth="0" marginheight="0">
    </iframe> -->
    <div class="bread">
        <span class="chinese_font">
            <!-- 用户管理 -->
            <router-link to="/Admin/User_admin" class="bread_text"
                active-class="active">用户管理
            </router-link>
            <span> | </span>
            <!-- 班级管理 -->
            <router-link to="/Admin/Class_admin" class="bread_text"
                active-class="active" v-if="$store.state.admin>=2">班级管理
            </router-link>
            <span class="bread_text grey" v-else>班级管理</span>
            <span> | </span>
            <!-- 作业管理 -->
            <router-link to="/Admin/Task_admin" class="bread_text"
                active-class="active" v-if="$store.state.admin>=1">作业管理
            </router-link>
            <span class="bread_text grey" v-else>作业管理</span>
            <span> | </span>
            <!-- 数据分析 -->
            <router-link to="/Admin/Echarts" class="bread_text"
                active-class="active">数据分析
            </router-link>
        </span>
    </div>
    <router-view></router-view>
</div>
</template>

<script>
export default {
    name:'MyAdmin',
}
</script>

<style scoped>
.bread{
    text-align:center;
    margin-top: 15vh;
}
.bread_text{
    font-size: 25px;
}
.bread_text:hover{
    color: dimgray;
}
.active{
    color: #ff8000 !important;
}
.bg{
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: -1;
}
</style>