<template>
<div class="flex_column">
    <div class="table_section">
        <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
        <el-table
            border
            stripe 
            height='70vh'
            :data="task_list"
            :default-sort = "{prop: 'date', order: 'descending'}"
        >
        <el-table-column
            prop="id"
            label="序号"
            sortable
            width="80"
            align="center">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题"
            sortable
            width="250"
            align="center">
        </el-table-column>
        <el-table-column
            prop="class_name"
            label="班级名"
            sortable
            width="150">
        </el-table-column>
        <el-table-column
            prop="publisher"
            label="发布者"
            sortable
            width="140"
            align="center">
        </el-table-column>
        <el-table-column
            label="启用AI老师"
            width="120"
            align="center">
            <template slot-scope="scope">
                <el-switch
                disabled
                v-model="scope.row.AI_teacher_if"
                active-color="#13ce66"
                inactive-color="#dcdfe6">
                </el-switch>
            </template>
        </el-table-column>
        <el-table-column
            prop="AI_teacher_list"
            label="AI老师"
            sortable
            width="220"
            align="center">
            <template slot-scope="scope">
                <div class="flex_row" style="flex-wrap: wrap;">
                    <div v-for="(store,index) in scope.row.AI_teacher_list" :key="index">
                        <el-tag style="margin-bottom: 3px;">{{store}}</el-tag>
                    </div>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="操作">
            <template slot="header">
                <el-button type="primary" plain 
                @click="addClassVisible=true">增加作业</el-button>
            </template>
            <template slot-scope="scope">
                <!-- 删除作业(操作权限3) -->
                <el-button
                size="mini"
                type="danger" plain
                v-if="$store.state.admin>=3"
                @click="Delete(scope.$index, scope.row)">删除
                </el-button>
                <el-button
                size="mini"
                type="danger" plain
                v-else
                disabled>删除
                </el-button>
            </template>
        </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="margin:2vh 0">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[25, 50, 75, 100]"
            :page-size="50"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </div>
    <!-- 增加作业-->
    <el-dialog
    title="增加作业"
    :visible.sync="addClassVisible"
    width="50%"
    top=5vh
    :before-close="handleClose">
        <Task_add :getTaskList="getTaskList" :handleClose="handleClose"></Task_add>
    </el-dialog>
</div>
</template>
    
<script>   
import axios from 'axios'
import Task_add from './Task_add.vue'
export default {
    name:"TaskAdmin",
    components: { Task_add },
    data() {
        return {
            currentPage: 1,          // 当前的页码
            page_size: 50,           // 每页显示条数
            task_list:[],      
            total:1,                 // 用户列表总长度
            addClassVisible: false,  // 增加班级弹出层
        }
    },
    methods: {
        // 关闭弹出框
        handleClose() {
            this.addClassVisible = false
        },
        // 删除
        Delete(index, row){
            console.log(index, row);
            this.$MessageBox.confirm('此操作将永久删除该作业,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 删除班级表中的班级
                let params = {
                    dataSheet: 'task_list',
                    id: row.id,
                }
                axios({
                    method:'get',
                    url:'https://xiaolily.cn/Delete.php',
                    params:params
                    }).then(
                    response => {
                        console.log('删除成功',response)
                        this.getTaskList()
                        // 删除成功提示
                        this.$message({
                            type: 'success',
                            message: response.data.msg
                        });
                    },
                    error => {
                        console.log('请求失败了',error.message)
                    }
                )
            }).catch(() => {
                console.log('用户点击取消')
            });
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getTaskList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getTaskList()
        },
        // 分页获取数据
        getTaskList(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
                class_name:'所有班级'
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Task_list_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    this.task_list = response.data.data
                    this.total = parseInt(response.data.total)  // element表格必需接受整数类型
                    for(var i=0;i<this.task_list.length;i++){
                        if(this.task_list[i].AI_teacher_if=='1'){
                            this.task_list[i].AI_teacher_if = true
                            this.task_list[i].AI_teacher_list = response.data.data[i].AI_teacher_list.match(/"(.{1,20}?)"/g)
                        }else{
                            this.task_list[i].AI_teacher_if = false
                        }
                    } 
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        this.getTaskList()
    }
}
</script>

<style scoped>
.table_section{
    width: 80%;
    margin-left: 5%;
    margin-top: 5vh;
}
</style>