<template>
<div class="flex_column">
  <div class="table_section">
    <!-- stripe属性创建带斑马纹的表格，border带边框,height='70vh'固定表头 -->
    <el-table
      border
      stripe 
      height='70vh'
      :data="fil_user_list"
      :default-sort = "{prop: 'date', order: 'descending'}"
    >
    <el-table-column
      prop="id"
      label="序号"
      sortable
      width="80"
      align="center">
    </el-table-column>
    <el-table-column
      prop="username"
      label="姓名"
      sortable
      width="150">
    </el-table-column>
    <el-table-column
      prop="class_name"
      label="班级"
      sortable
      width="120">
    </el-table-column>
    <el-table-column
      prop="admin"
      label="权限"
      width="80"
      sortable
      align="center">
    </el-table-column>
    <el-table-column
      prop="jifen"
      label="积分"
      sortable
      width="80"
      align="center">
    </el-table-column>
    <el-table-column
      prop="lv"
      label="等级"
      sortable
      width="80"
      align="center">
    </el-table-column>
    <el-table-column
      prop="task_scope"
      label="作业绩点"
      sortable
      width="110"
      align="center">
    </el-table-column>
    <el-table-column
      prop="average_score"
      label="考试平均分"
      sortable
      width="120"
      align="center">
    </el-table-column>
    <el-table-column label="操作">
      <!-- 班级选择,getUserInfo不需要传参,只是因为scope如果不用就会报错 -->
      <template slot="header" slot-scope="scope" >
        <div class="flex_row">
          <el-select v-model="choosed_class" filterable placeholder="请选择班级"
          @change="getUserInfo(scope)">
            <el-option
            v-for="item in class_list"
            :key="item.id"
            :label="item.class_name"
            :value="item.class_name">
            </el-option>
          </el-select>
          <!-- 姓名搜索 -->
          <el-input style="margin-left: 30px;"
            v-model="keyWord" 
            placeholder="搜索姓名">
          </el-input>
        </div>
      </template>
      <template slot-scope="scope">
        <!-- 提权(只能从0-1,从1-0)(操作权限2) -->
        <el-button type="success" plain
          size="mini"
          @click="setAdmin(scope.$index, scope.row,1)"
          v-if="$store.state.admin>=2&&scope.row.admin==0">提权
        </el-button>
        <el-button type="success" plain
          size="mini"
          disabled
          v-else>提权
        </el-button>
        <!-- 降权(只能从0-1,从1-0)(操作权限2) -->
        <el-button type="warning" plain
          size="mini"
          @click="setAdmin(scope.$index, scope.row,0)"
          v-if="$store.state.admin>=2&&scope.row.admin==1">降权
        </el-button>
        <el-button type="warning" plain
          size="mini"
          disabled
          v-else>降权
        </el-button>
        <!-- 删除(只能删除权限0)(操作权限2) -->
        <el-button
          size="mini"
          type="danger" plain
          v-if="$store.state.admin>=2&&scope.row.admin==0"
          @click="Delete(scope.$index, scope.row)">删除
        </el-button>
        <el-button
          size="mini"
          type="danger" plain
          v-else
          disabled>删除
        </el-button>
        <!-- 重置密码(只能重置0)(操作权限2) -->
        <el-button
          size="mini"
          type="info" plain
          v-if="$store.state.admin>=2&&scope.row.admin==0"
          @click="resetPassword(scope.$index, scope.row)">重置
        </el-button>
        <el-button
          size="mini"
          type="info" plain
          v-else
          disabled>重置
        </el-button>
        <!-- 加分(操作权限2) -->
        <el-button type="primary" plain
          size="mini"
          @click="addJifen(scope.$index, scope.row)"
          v-if="$store.state.admin>=2">加分
        </el-button>
        <el-button type="primary" plain
          size="mini"
          disabled
          v-else>加分
        </el-button>
      </template>
    </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div style="margin:2vh 0">
      <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="currentPage"
      :page-sizes="[25, 50, 75, 100]"
      :page-size="50"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:"UserAdmin",
    data() {
      return {
        currentPage: 1,       // 当前的页码
        page_size: 50,        // 每页显示条数
        user_list:[{},{}],    // 用户信息列表
        total:1,              // 用户列表总长度
        class_list:[{},{}],   // 班级列表，用于下拉框选择班级
        choosed_class:'',     // 用户通过下拉选择框选中的班级
        keyWord:'',           // 关键词搜索
        fil_user_list:''
      }
    },
    watch:{
      keyWord:{
        immediate:true,
        handler(val){
          this.fil_user_list = this.user_list.filter((p)=>{
            return p.username.indexOf(val) !== -1
          })
        }
      }
		},
    methods: {
      // 设置为管理员权限1
      setAdmin(index, row, action) {
        console.log(index, row.id);
        let params = {
          action: action, // 操作类型:0取消管理员,1设置管理员
          id: row.id,
        }
        axios({
            method:'get',
            url:'https://xiaolily.cn/UserAdmin_mod.php',
            params:params
        }).then(
            response => {
              console.log('修改权限',response)
              this.getUserInfo()
            },
            error => {
                console.log('请求失败了',error.message)
            }
        )
      },
      // 增加50积分和经验
      addJifen(index, row) {
        console.log(index, row.id);
        let params = {
          id: row.id,
        }
        axios({
            method:'get',
            url:'https://xiaolily.cn/UserAdmin_mod_jifen.php',
            params:params
        }).then(
            response => {
              console.log('增加积分',response)
              this.$message({
                  message: response.data.msg,
                  type: 'success',
                  duration:1000
              })
              this.getUserInfo()
            },
            error => {
                console.log('请求失败了',error.message)
            }
        )
      },
      // 重置密码为123456
      resetPassword(index, row) {
        console.log(index, row.id);
        this.$MessageBox.confirm('是否确定将该用户的密码重置为123456?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let params = {
					id: row.id,
          }
          axios({
            method:'get',
            url:'https://xiaolily.cn/Password_reset.php',
            params:params
          }).then(
            response => {
              console.log(response)
              if (response.data.error == 0){
                this.$message({
                  message: response.data.msg,
                  type: 'success'
                })
              }
              else{
                this.$message.error(response.data.msg);
              }
            },
            error => {
              console.log('请求失败了',error.message)
            }
          )
        }) 
      },
      // 删除用户
      Delete(index, row){
        console.log(index, row);
        this.$MessageBox.confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          // 发送请求
          let params = {
          dataSheet: 'users',
          id: row.id,
          }
          axios({
            method:'get',
            url:'https://xiaolily.cn/Delete.php',
            params:params
          }).then(
            response => {
              console.log('删除用户',response)
              this.getUserInfo()
              // 删除成功提示
              this.$message({
                type: 'success',
                message: response.data.msg
              });
            },
            error => {
                console.log('请求失败了',error.message)
            }
          )
        })
      },
      // 改变每页显示数量事件
      handleSizeChange(val) {
          console.log(`每页 ${val} 条`);
          this.page_size = val
          this.getUserInfo()
      },
      // 页码切换事件
      handleCurrentChange(val) {
          console.log(`当前页: ${val}`);
          this.getUserInfo()
      },
      // 分页获取users表中数据
      getUserInfo(){
          // 发送数据
          let params = {
            currentPage: this.currentPage,
            page_size: this.page_size,
            class_name:this.choosed_class
          }
          axios({
              method:'get',
              url:'https://xiaolily.cn/Users_get.php',
              params:params
          }).then(
              response => {
                this.user_list = response.data.data
                this.fil_user_list = response.data.data
                this.total = parseInt(response.data.total)  // 他们那个表格必需接受整数类型
                // console.log('用户列表',this.user_list)
                // console.log(this.total)
                // console.log(typeof(this.total))   
              },
              error => {
                  console.log('请求失败了',error.message)
              }
          )
      },
      // 获取班级列表
      getClassList(){
        // 发送数据
        let params = {
          currentPage: 1,
          page_size: 100  
        }
        axios({
            method:'get',
            url:'https://xiaolily.cn/Class_get.php',
            params:params
        }).then(
            response => {
              // console.log('获取班级:',response)
              this.class_list = response.data.data
              // console.log(this.class_list)
            },
            error => {
              console.log('请求失败了',error.message)
            }
        )
      }
    },
    mounted(){
        this.getUserInfo()
        this.getClassList()
    }
}
</script>

<style scoped>
.table_section{
    width: 80%;
    margin-left: 5%;
    margin-top: 5vh;
}
</style>