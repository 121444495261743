//引入Vue核心库
import Vue from 'vue'
//引入Vuex
import Vuex from 'vuex'
//应用Vuex插件
Vue.use(Vuex)

//准备actions对象——响应组件中用户的动作
const actions = {}
//准备mutations对象——修改state中的数据
const mutations = {
	// 修改头像
	MOD_AVATAR(state, value) {
		state.avatar = value
	},
	// 获取缓存
	SESSION(state, userinfo) {
		state.username = userinfo[0]
		state.user_id = userinfo[1]
		state.class_num = userinfo[2]
		state.admin = userinfo[3]
		state.admin_name = userinfo[4]
		state.avatar = userinfo[5]
		state.class_name = userinfo[6]
	}
}
//准备state对象——保存具体的数据
const state = {
	username: '',     	// 用户名
	user_id: '',       	// 用户ID
	class_num: '',       // 班级码
	admin: '',           // 管理员权限、0无权限、1班级管理员、2管理员、3超级管理员
	admin_name: '',      // 管理员名称，方便前端展示
	avatar: '',          // 用户头像
	class_name: '',      // 班级名
}

// 准备getters——用于将state中的数据进行加工
const getters = {
}

//创建并暴露store
export default new Vuex.Store({
	actions,
	mutations,
	state,
	getters
})