<template>
<div>
  <!-- 卡片容器 -->
  <el-card class="box-card chinese-font">
    <!-- 输入框 -->
    <div slot="header" class="clearfix" @keyup.enter="add">
      <el-input
        type="text"
        placeholder="请输入内容，按下回车确定。(网站信息易被黑客窃取，请勿输入隐私内容)"
        v-model="userinput"
        maxlength="30"
        show-word-limit
      >
      </el-input>
    </div>
    <!-- 待办项 -->
    <div v-for="todo in todos" :key="todo.index" class="text item" >
      <el-checkbox border size="medium" 
      v-model="todo.done">{{todo.content}}</el-checkbox>
      <!-- 删除按钮 -->
      <el-button class="delete_button" @click="del(todo.content)"
      type="danger" icon="el-icon-delete" circle></el-button>
    </div>
  </el-card>
</div>
</template>
 
<script>
import axios from 'axios'
export default {
    name:'MyMemo',
    data() {
      return {
        userinput: '',                     // 用户的输入
        todos:[]                           // 备忘项
      }
    },
    methods:{
      // 将本地数据上传至数据库
      update(){  
        var todos_JsonStr = JSON.stringify(this.todos) // js对象转JSON字符串
        axios.post('https://xiaolily.cn/demo_add.php?Info='+todos_JsonStr).then(
            response => {
                console.log(response)
            },
            error => {
                console.log('请求失败了',error.message)
            }
        )
      },
      // 增加一条备忘信息
      add(){
        //校验数据
				if(!this.userinput.trim()){
          this.$message({
              type:'warning',
              message:'前端:内容为空'
          })
          return false
        }
				//将用户的输入包装成一个todo对象,然后添加到列表头
				const todoObj = {content:this.userinput,done:false}
        this.todos.unshift(todoObj)
        this.update()
				//清空输入
				this.userinput = ''
      },
      // 删除一条备忘信息
      del(content){
        this.todos = this.todos.filter((todo)=>{
          return todo.content !== content
        })
        this.update()
      },
    },
    mounted(){
      // 获得数据库内容
      axios.post('https://xiaolily.cn/demo_get.php').then(
          response => {
            // json字符串转js对象
            this.todos = JSON.parse(response.data.data[0].todos)
          },
          error => {
              console.log('请求失败了',error.message)
          }
      )
    }
}
</script>

<style scoped>
.box-card{
  width: 50vw !important;
  margin-left: 25vw;
  margin-top: 15vh;
  min-height: 60vh;
}
.text {
  font-size: 34px;
}

.item {
  margin-bottom: 14px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}

.box-card {
  width: 480px;
}

.delete_button{
  float:right;
}
</style>