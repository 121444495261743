<template>
<div id="rank_section">
    <div :id="title" style="width: 100%;height:400px;"></div>
</div> 
</template>

<script>
import axios from 'axios'
export default {
    name:'ScoreRank',
    props:['class_num','post_url','title','field'],//班级、请求地址、图表标题
    watch: {
        class_num(newMessage, oldMessage) {
            this.mountCharts();
        },
    },
    methods:{
        // 挂载图表
        mountCharts(){
            axios.post(this.post_url+'?class_num='+this.class_num+'&field='+this.field
            ).then(
                response => {
                    console.log(response)
                    var d = response.data
                    // 基于准备好的dom，初始化echarts实例
                    // 如果想有两个实例化的对象，就不能都用main，取而代之的应该是一个不同的ID名
                    var myChart = window.echarts.init(document.getElementById(this.title));
                    // prettier-ignore
                    let dataAxis = d.dataAxis; //柱子上的字
                    // prettier-ignore
                    let data = d.data;     //每列数据
                    let yMax = 500;
                    let dataShadow = [];
                    for (let i = 0; i < data.length; i++) {
                        dataShadow.push(yMax);
                        }
                        window.option = {
                        title: {
                            text: this.title,
                            subtext: 'Leaderboard'
                        },
                        xAxis: {
                            data: dataAxis,
                            axisLabel: {
                            inside: true,
                            color: '#fff'
                            },
                            axisTick: {
                            show: false
                            },
                            axisLine: {
                            show: false
                            },
                            z: 10
                        },
                        yAxis: {
                            axisLine: {
                            show: false
                            },
                            axisTick: {
                            show: false
                            },
                            axisLabel: {
                            color: '#999'
                            }
                        },
                        dataZoom: [
                            {
                            type: 'inside'
                            }
                        ],
                        series: [
                            {
                            type: 'bar',
                            showBackground: true,
                            itemStyle: {
                                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                { offset: 0, color: '#83bff6' },
                                { offset: 0.5, color: '#188df0' },
                                { offset: 1, color: '#188df0' }
                                ])
                            },
                            emphasis: {
                                itemStyle: {
                                color: new window.echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    { offset: 0, color: '#2378f7' },
                                    { offset: 0.7, color: '#2378f7' },
                                    { offset: 1, color: '#83bff6' }
                                ])
                                }
                            },
                            data: data
                            }
                        ]
                    };
                    // Enable data zoom when user click bar.
                    const zoomSize = 6;
                    myChart.on('click', function (params) {
                    console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
                    myChart.dispatchAction({
                        type: 'dataZoom',
                        startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
                        endValue:
                        dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
                        });
                    });
                    // 使用刚指定的配置项和数据显示图表。
                    myChart.setOption(window.option);
                    console.log(myChart)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        
    }
}
</script>

<style>
#rank_section{
    width: 100%;
}
</style>