<template>
    <body class="chinese_font">
        <div class="flex_row" style="justify-content: flex-start;flex-wrap: wrap;">
            <div v-for="(i,index) in data_list" :key="index"
                class="avatar_box flex_column" style="justify-content: flex-start;align-items: flex-start;">
                <!-- 头像 -->
                <div style="width: 100%;height: 7vw;" class="flex_row">
                    <img class="avatar_img" style="width: 6vw;height: 6vw;border-radius: 10px;"
                    :src="'https://xiaolily.cn/'+i.file_dir+'/'+i.file_name" alt="">
                </div>
                <p class="avatar_title">{{i.avatar_name}}</p>
                <div style="min-height: 4vh;">
                    <p class="avatar_des">{{i.avatar_des}}</p>
                </div>
                <div class="avatar_info flex_column" style="align-items: flex-end;">
                    <p class="gray" style="font-size: 12px;">作者：{{i.username}}</p>
                    <p class="gray" style="font-size: 12px;">已售出: {{i.earn_count}}份</p>
                    <p class="gray" style="font-size: 12px;">累计收益: {{i.earn_money}}积分</p>
                </div>
                <!-- 底部图标 -->
                <div class="price_and_buy flex_row">
                    <div class="flex_row">
                        <img width="20px"
                        src="../../assets/image/jifen.png" alt="">
                        <span>{{i.price}}</span>
                    </div>
                    <div class="flex_row">
                        <i v-if="$store.state.admin>=2"
                        @click="del(i.id,i.file_dir,i.file_name)" 
                        class="el-icon-delete" style="margin-right: 5px;"></i>
                        <i @click="buy(i.price,i.file_dir,i.file_name,i.username,i.avatar_name)"
                        class="el-icon-goods buy_icon"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- 分页 -->
        <div class="pagination">
            <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-sizes="[12, 24, 36, 48]"
            :page-size="12"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
            </el-pagination>
        </div>
    </body>
</template>

<script>
import axios from 'axios'
export default {
    name:'ShopShop',
    data() {
        return {
            currentPage: 1,       // 当前的页码
            page_size: 12,        // 每页显示条数
            total:1,              // 题库列表总长度
            data_list:[]
        }
    },
    methods:{
        // 删除商品
        del(id,file_dir,file_name){
            this.$MessageBox.confirm('此操作将永久删除该商品,是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const file_url = file_dir+'/'+file_name
                // 发送请求
                let params = {
                    dataSheet: 'shop',
                    id: id,
                    file_url: file_url,
                }
                axios({
                    method:'get',
                    url:'https://xiaolily.cn/Delete.php',
                    params:params
                }).then(
                    response => {
                    this.getDataList()
                    // 删除成功提示
                    this.$message({
                        type: 'success',
                        message: response.data.msg
                    });
                    },
                    error => {
                        console.log('请求失败了',error.message)
                    }
                )
            }).catch(() => {
                console.log('用户点击取消')
            });
        },
        // 购买商品
        buy(price,file_dir,file_name,username,avatar_name){
            this.$MessageBox.confirm('你确定要花费'+price+'积分购买该件商品吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                // 发送请求
                let params = {
                    file_dir: file_dir,
                    file_name: file_name,
                    owner:username,
                    price:price,
                    avatar_name:avatar_name
                }
                axios({
                    method:'get',
                    url:'https://xiaolily.cn/Shop_avatar_buy.php',
                    params:params
                }).then(
                    response => {
                        console.log(response)
                        if(response.data.error == 0){
                            this.$message({
                                type: 'success',
                                message: response.data.msg
                            });
                            this.getDataList()
                        }
                        else{
                            this.$message({
                                type:'warning',
                                message:response.data.msg
                            })
                        }
                    },
                )
            }).catch(() => {
                console.log('用户点击取消')
            });
        },
        // 分页获取数据
        getDataList(){
            // 发送数据
            let params = {
                currentPage: this.currentPage,
                page_size: this.page_size,
                audit:1
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Shop_get.php',
                params:params
            }).then(
                response => {
                    this.data_list = response.data.data
                    this.total = parseInt(response.data.total)
                    // console.log(this.data_list)
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 改变每页显示数量事件
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
            this.page_size = val
            this.getDataList()
        },
        // 页码切换事件
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getDataList()
        },
    },
    mounted(){
        this.getDataList()
    }
}
</script>

<style scoped>
.pagination{
    text-align: center;
    margin-top: 20px;
}
.buy_icon:hover,.el-icon-delete:hover{
    color: red;
}
.avatar_info{
    width: 100%;
}
.gray{
    color: gray;
    font-size: small;
}
.price_and_buy{
    width: 100%;
    margin: 10px 0;
    justify-content: space-between;
}
.avatar_des{
    font-size: small;
}
.avatar_title{
    margin: 5px 0;
}
body{
    position: relative;
    width: 80vw;
    margin-left: 12vw;
    margin-top: 2vh;
    min-height: 80vh;
    background-color: rgba(255, 255, 255, 0.5);
}
.avatar_box{
    width: 13%;
    height: 33vh;
    box-sizing: border-box;
    padding: 10px;
    margin: 10px;
    background-color: white;
    border-radius: 15px;
    box-sizing: border-box;
    position: relative;
}
.avatar_box:hover{
    box-shadow: 0 0 10px gray;
}
.avatar_img{
    transition: 0.2s linear all;
}
.avatar_box:hover .avatar_img{
    transform: scale(0.95);
}
</style>