// 该文件专门用于创建整个应用的路由器
import VueRouter from 'vue-router'
//引入组件
import Index from '../pages/Index'
import Message from '../pages/Message'
import About from '../pages/About'
import Lucky from '../pages/Lucky'
import Memo from '../pages/Memo'
import Dict from '../pages/Dict'
import File from '../pages/File'
import AI from '../pages/AI'
import BigData from '../pages/BigData'
import Task_detail from '../pages/Task/Task_detail'
import Admin from '../pages/Admin/Admin'
import User_admin from '../pages/Admin/User_admin'
import Class_admin from '../pages/Admin/Class_admin'
import Task_admin from '../pages/Admin/Task_admin'
import Echarts from '../pages/Admin/Echarts'
import Exam_index from '../pages/Exam/Exam_index'
import Exam from '../pages/Exam/Exam'
import Store from '../pages/Exam/Store'
import Store_edit from '../pages/Exam/Store_edit'
import Store_look from '../pages/Exam/Store_look'
import Exam_exam from '../pages/Exam/Exam_exam'
import Exam_score from '../pages/Exam/Exam_score'
import Exam_look from '../pages/Exam/Exam_look'
import Shop_index from '../pages/Shop/Shop_index'
import Shop_audit from '../pages/Shop/Shop_audit'
import Shop_shop from '../pages/Shop/Shop_shop'
import Shop_history from '../pages/Shop/Shop_history'
import Shop_card from '../pages/Shop/Shop_card'

//创建并暴露一个路由器
const router = new VueRouter({
	routes: [
		{
			path: '/',
			redirect: '/index'
		},
		{
			path: '/index',
			component: Index
		},
		{
			path: '/message',
			component: Message
		},
		{
			path: '/about',
			component: About
		},
		{
			path: '/Lucky',
			component: Lucky
		},
		{
			path: '/Memo',
			component: Memo
		},
		{
			path: '/Dict',
			component: Dict
		},
		{
			path: '/File',
			component: File
		},
		{
			path: '/AI',
			component: AI
		},
		{
			path: '/BigData',
			component: BigData
		},
		{
			name: 'Task_detail',
			path: '/Task_detail',
			component: Task_detail
		},
		// 管理
		{
			path: '/Admin',
			component: Admin,
			//通过children配置子级路由
			children: [
				{
					path: 'User_admin', //此处一定不要写：/User_admin
					component: User_admin
				},
				{
					path: 'Class_admin',
					component: Class_admin
				},
				{
					path: 'Task_admin',
					component: Task_admin
				},
				{
					path: 'Echarts',
					component: Echarts
				}
			]
		},
		// 考试
		{
			path: '/Exam_index',
			component: Exam_index,
			children: [
				{
					name: 'exam',
					path: 'Exam',
					component: Exam
				},
				{
					path: 'Store',
					component: Store
				},
				{
					path: 'Store_edit',
					component: Store_edit
				},
				{
					path: 'Store_look',
					component: Store_look
				},
				{
					path: 'Exam_exam',
					component: Exam_exam,
					meta: {
						isAuth: true
					}
				},
				{
					path: 'Exam_score',
					component: Exam_score
				},
				{
					path: 'Exam_look',
					component: Exam_look
				},
			]
		},
		// 积分商城
		{
			path: '/Shop_index',
			component: Shop_index,
			children: [
				{
					path: 'Shop_shop',
					component: Shop_shop
				},
				{
					path: 'Shop_audit',
					component: Shop_audit
				},
				{
					path: 'Shop_history',
					component: Shop_history
				},
				{
					path: 'Shop_card',
					component: Shop_card
				},
			]
		},
	]
})

//全局前置守卫：初始化时执行、每次路由切换前执行
router.beforeEach((to, from, next) => {
	// console.log('beforeEach', to, from)
	if (to.meta.isAuth) { //判断当前路由是否需要进行权限控制
		// 考试页面,不能通过改exam_id刷新页面进入别的班级的试卷了
		if (
			to.path == '/Exam_index/Exam_exam' &&
			(from.path == '/Exam_index/Exam' || from.path == '/Exam_index/Exam_score')
		) {
			next() //放行
		} else {
			alert('本页面加入了路由守卫，不要刷新，请重新进入考试')
		}
	} else {
		next() //放行
	}
})

export default router