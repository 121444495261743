<template>
    <div id="loading">
        <div id="loading_bg" ref="loading">
            <div class="loader">Loading...</div>
        </div>
    </div>
</template>

<script>
export default {
    name:'MyLoading',
    mounted() {
        this.$nextTick(loadingFade())
        function loadingFade() {
            var opacity=1;
            var loadingPage=document.getElementById('loading');
            var loadingBackground=document.getElementById('loading_bg');
            var time=setInterval(function () {
                if (opacity<=0){
                    clearInterval(time);
                    loadingPage.remove();
                }
                loadingBackground.style.opacity=opacity;
                opacity-=0.4;
            },50);
        }
    },
}
</script>

<style scoped>
    #loading {
        position: absolute;
        background-color: #FFF;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        z-index: 9999;
    }
    #loading_bg{
        background-color: rgba(0,0,0,0.7);
    }
    .loader {
        width: 250px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: helvetica, arial, sans-serif;
        text-transform: uppercase;
        font-weight: 900;
        color: #ce4233;
        letter-spacing: 0.2em;
    }
    .loader::before, .loader::after {
        content: "";
        display: block;
        width: 15px;
        height: 15px;
        background: #ce4233;
        position: absolute;
        -webkit-animation: load .7s infinite alternate ease-in-out;
        animation: load .7s infinite alternate ease-in-out;
    }
    .loader::before {
        top: 0;
    }
    .loader::after {
        bottom: 0;
    }
    @-webkit-keyframes load {
        0% {
        left: 0;
        height: 30px;
        width: 15px;
        }
        50% {
        height: 8px;
        width: 40px;
        }
        100% {
        left: 235px;
        height: 30px;
        width: 15px;
        }
    }
    @keyframes load {
        0% {
        left: 0;
        height: 30px;
        width: 15px;
        }
        50% {
        height: 8px;
        width: 40px;
        }
        100% {
        left: 235px;
        height: 30px;
        width: 15px;
        }
    }
</style>