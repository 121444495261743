<template>
<div>
    <nav id="nav" class="chinese_font indexmainxontent">
        <!-- 左侧logo -->
        <router-link active-class="active" to="/index">
            <img src="../assets/image/logo.png" id="logo">
        </router-link>
        <!-- 右侧导航列表 -->
        <ul id="nav_list">
            <router-link active-class="active" to="/index">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-home faa-wrench"></i>首页
                </li>
            </router-link>
            <!-- 考试 -->
            <router-link active-class="active" to="/Exam_index/Exam">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-paper-plane faa-shake"></i>考试
                </li>
            </router-link>
            <!-- 点名 -->
            <router-link class="nav_box" active-class="active" to="/Lucky">
                <li>
                    <span class="faa-parent animated-hover no_wrap">
                        <i class="fa fa-hand-paper-o faa-bounce"></i>点名
                    </span>
                </li>
            </router-link>
            <!-- 翻译 -->
            <!-- <router-link class="nav_box" active-class="active" to="/Dict">
                <li>
                    <span class="faa-parent animated-hover no_wrap">
                        <i class="fa fa-reddit-alien faa-vertical"></i>翻译
                    </span>
                </li>
            </router-link> -->
            <!-- 备忘、已登录则显示 -->
            <router-link class="nav_box" active-class="active" to="/Memo">
                <li v-if="$store.state.username">
                    <span class="faa-parent animated-hover no_wrap">
                        <i class="fa fa-github-alt faa-tada"></i>备忘
                    </span>
                </li>
            </router-link>
            <!-- 登录（已登录则不显示） -->
            <li @click="LoginVisible = true" v-if="!$store.state.username"
            class="faa-parent animated-hover nav_box no_wrap">
                <i class="fa fa-user faa-horizontal"></i>登录
            </li>
            <!-- 注册（已登录则不显示） -->
            <li @click="SignupVisible = true" v-if="!$store.state.username"
            class="faa-parent animated-hover nav_box no_wrap">
                <i class="fa fa-pencil faa-tada"></i>注册
            </li>
            <!-- 留言板 -->
            <router-link active-class="active" to="/message">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-pencil-square-o faa-tada"></i>留言板
                </li>
            </router-link>
            <!-- 商城 -->
            <router-link active-class="active" to="/Shop_index/Shop_shop">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-shopping-cart faa-tada"></i>商城
                </li>
            </router-link>
            <!-- 网盘 -->
            <router-link class="nav_box" active-class="active" to="/File">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-user-circle-o faa-tada"></i>网盘
                </li>
            </router-link>
            <!-- AI -->
            <router-link class="nav_box" active-class="active" to="/AI">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-reddit-alien faa-flash"></i>AI
                </li>
            </router-link>
            <!-- 关于 -->
            <router-link active-class="active" to="/about">
                <li class="faa-parent animated-hover no_wrap">
                    <i class="fa fa-leaf faa-flash"></i>关于
                </li>
            </router-link>
            <!-- 笔记 -->
            <li>
                <a class="nav_box" id="note" href="https://note.xiaolily.cn" target="_blank">
                    <span class="faa-parent animated-hover no_wrap">
                        <i class="fa fa-bookmark faa-float"></i>笔记
                    </span>
                </a>
            </li>
        </ul>
    </nav>
    <!-- 用户登录弹出层 -->
    <el-dialog
		title="用户登录"
		:visible.sync="LoginVisible"
		width="25%"
		:before-close="handleClose"
    >
        <Login :handleClose="handleClose"></Login>
    </el-dialog>
    <!-- 用户注册弹出层 -->
    <el-dialog
		title="用户注册"
		:visible.sync="SignupVisible"
		width="25%"
		top=5vh
		:before-close="handleClose"
	>
        <Signup :handleClose="handleClose"></Signup>
    </el-dialog>
</div>
</template>

<script>
import Login from './Login'
import Signup from './Signup'
export default {
    name:'MyNav',
    components:{Login,Signup},
    data(){
        return{
            LoginVisible: false,
            SignupVisible: false,
        }
    },
    methods: {
        // 关闭弹出框
        handleClose() {
            this.LoginVisible = false
            this.SignupVisible = false
        }
    }
}
</script>

<style scoped>
#nav{
    position: fixed;
    width: 95vw;
    left: 2.5vw;
    top: 2.5vh;
    z-index: 999;
    border-radius: 20px;
    font-size: 20px;
}
#logo{
    position: absolute;
    left: 0px;
    height: 50px;
    top: 50%;
    margin-top: -25px;
    margin-left: 25px;
}
#nav_list{
    display: flex;
    flex-direction: row;
    float: right;
    list-style-type: none;    /* 不显示列表前面的小圆点 */
    margin: 0px;
}
#nav_list li{
    margin: 20px;
}
#nav_list li:hover{
    color: red;
}
.active{
    color: red;
}
/* 鼠标指上去、或者下滑、背景逐渐变白 */
#nav:hover,.nav_hover{
    background-color: rgba(255, 255, 255, .5);
	backdrop-filter: blur(3px);
    transition: all 0.5s linear;
}
/* 回到顶部之后逐渐变黑 */
.nav_fade{
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.5s linear;
}

#note :hover{
    color: red;
}

li {
  position: relative; /* 为了设置下划线的位置 */
  display: inline-block; /* 使下划线仅在文字的下方出现，并且长度随文字而定 */
}

li::after {
  content: ''; /* 伪类必须要设置content */
  position: absolute; /* 使下划线相对于父元素（即p）定位 */
  bottom: 0px; /* 放置在p的底部 */
  top: 30px;
  left: 0; /* 与p的左侧对齐 */
  width: 0; /* 初始宽度为0 */
  height: 3px; /* 下划线高度 */
  background-color: red; /* 下划线颜色 */
  transition: width 0.5s ease-in-out; /* 设置下划线宽度渐变效果 */
}

li:hover::after { 
  width: 100%; /* 让下划线随着鼠标的移动而逐渐增长，这里设置为100%代表宽度与文本宽度一致 */
}

/* .indexmainxontent{
    overflow:hidden;
    white-space:nowrap;
    position: relative;
    width: 90%;
    left: 5%;
    z-index: 1;
    border-radius: 20px;
    animation:indexmainxontent 1s;
    animation-timing-function:linear;
    background-image: linear-gradient(to right, rgba(135, 206, 235,0.5),  rgba(255, 255, 255,0.5)) ;
    margin-bottom: 2rem;
}
@keyframes indexmainxontent{
    from{
        width: 0%;
        text-indent: -200%;
    }
    to{
        width: 90%;
    }
} */
</style>