<template>
<div class="flex_column">
    <el-form 
        :label-position="labelPosition" 
        label-width="80px" 
        style="margin-bottom: 20px;width: 60%;"
    >
        <!-- 标题 -->
        <el-form-item label="标题">
            <el-input v-model.trim="title"
            placeholder="请输入作业标题"></el-input>
        </el-form-item>
        <!-- 班级选择 -->
        <el-form-item label="班级">
            <el-select v-model="choose_class" placeholder="请选择班级">
                <el-option
                v-for="item in class_list"
                :key="item.class_name"
                :label="item.class_name"
                :value="item.class_name">
                </el-option>
            </el-select>
        </el-form-item>
        <!-- 是否启用AI老师 -->
        <el-form-item label="AI老师">
            <el-switch v-model="AI_teacher_if"></el-switch>
        </el-form-item>
        <!-- 选择AI老师 -->
        <el-checkbox-group 
        :disabled="!AI_teacher_if" 
        v-model="AI_teacher_list" 
        class="flex_row"
        style="flex-wrap: wrap;justify-content: flex-start;"
        :min="1"
        :max="5"
        >
            <el-checkbox label="人工智能专家"></el-checkbox>
            <el-checkbox label="高考语文名师"></el-checkbox>
            <el-checkbox label="郭德纲"></el-checkbox>
            <el-checkbox label="林黛玉"></el-checkbox>
            <el-checkbox label="唐僧"></el-checkbox>
        </el-checkbox-group>
        <!-- 题目要求 -->
        <el-form-item 
        label="题目要求" 
        style="margin-top: 30px;"
        >
            <el-input
            :disabled="!AI_teacher_if" 
            type="textarea"
            placeholder="AI老师会根据题目要求对作业进行点评"
            v-model="timu_require"
            maxlength="50"
            show-word-limit
            >
        </el-input>
        </el-form-item>
    </el-form>
    <!-- 提交按钮 -->
    <el-button type="primary" plain @click="submit">提交</el-button>
</div>
</template>

<script>
import axios from 'axios'
export default {
    name:'ClassAdd',
    props:['getTaskList','handleClose'],
    data() {
        return {
            labelPosition: 'right',      //对齐方式，还有left和top   
            title: '',
            class_list:[],
            choose_class:'',
            AI_teacher_if:false,    // 是否启用AI老师  
            AI_teacher_list:[],     // 选择的AI老师             
            timu_require:'',        // 题目要求                  
        }
    },
    methods: {
        // 获取班级列表
        ClassListGet(){
            // 发送请求
            let params = {
                currentPage: 1,
                page_size: 100,
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Class_get.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    this.class_list = response.data.data
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        },
        // 提交按钮
        submit(event){
            // 前端表单校验
            if(!this.title){
                this.$message({
                    type:'warning',
                    message:'前端:标题没填'
                })
                event.preventDefault()
                return false
            }
            if(!this.choose_class){
                this.$message({
                    type:'warning',
                    message:'前端:班级没选'
                })
                event.preventDefault()
                return false
            }
            // 发送请求
            let params = {
                title: this.title,
                class_name: this.choose_class,
                AI_teacher_if: this.AI_teacher_if,
                AI_teacher_list: JSON.stringify(this.AI_teacher_list),
                timu_require:this.timu_require
            }
            axios({
                method:'get',
                url:'https://xiaolily.cn/Task_list_add.php',
                params:params
            }).then(
                response => {
                    // console.log(response)
                    if(response.data.error == 0){
                        this.getTaskList()  // 再次请求,更新新数据
                        this.handleClose()   // 关闭弹出层
                        this.$message({
                            type:'success',
                            message:response.data.msg
                        })
                    }else{
                        this.$message.error(response.data.msg)
                    }
                },
                error => {
                    console.log('请求失败了',error.message)
                }
            )
        }
    },
    mounted(){
        this.ClassListGet()
    }
}
</script>

<style scoped>
</style>