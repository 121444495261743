<template>
<div class="site_info">
    <a href="http://10.163.72.12:8888" target="_blank">
        <img src="../assets/image/sakura.svg" class="rotating">
    </a>
    <!-- <router-link to="/BigData">
        <img src="../assets/image/sakura.svg" class="rotating">
    </router-link> -->
    <p>Copyright © by 彭韦浩 All Rights Reserved <a href="http://www.beian.miit.gov.cn/">鲁ICP备2024062241号</a></p>
    <div class="chinese_font" style="margin-top:15px;font-size:18px"> 
        <span id="timeDate" >本站已安全运行 {{days}} 天</span><span id="times"> {{hours}} 小时 {{minutes}} 分 {{seconds}} 秒</span>
        <div id="binft" style="margin-top:15px;"></div>
    </div>
</div>
</template>

<script>
	export default {
		name:'MyFooter',
        data() {
            return {
                days:'',
                hours:'',
                minutes:'',
                seconds:'',
            }
        },
        methods:{
            // 运行时间统计
            createtime(){
                var now = new Date();
                var grt= new Date("02/12/2022 16:44:00");//此处修改你的建站时间或者网站上线时间
                now.setTime(now.getTime()+250);
                window.days = (now - grt ) / 1000 / 60 / 60 / 24; window.window.dnum = Math.floor(window.days);
                window.hours = (now - grt ) / 1000 / 60 / 60 - (24 * window.dnum); window.hnum = Math.floor(window.hours);
                if(String(window.hnum).length ==1 ){window.hnum = "0" + window.hnum;} window.minutes = (now - grt ) / 1000 /60 - (24 * 60 * window.dnum) - (60 * window.hnum);
                window.mnum = Math.floor(window.minutes); if(String(window.mnum).length ==1 ){window.mnum = "0" + window.mnum;}
                window.seconds = (now - grt ) / 1000 - (24 * 60 * 60 * window.dnum) - (60 * 60 * window.hnum) - (60 * window.mnum);
                window.snum = Math.round(window.seconds); if(String(window.snum).length ==1 ){window.snum = "0" + window.snum;}
                this.days = window.dnum
                this.hours = window.hnum
                this.minutes = window.mnum
                this.seconds = window.snum
            }
        },
        mounted() {
            // 开启网站计时定时器
            setInterval(this.createtime, 1000);
            // 底部动态滚动文字
            var binft = function (r) {
                function t() {
                return b[Math.floor(Math.random() * b.length)]
                }
                function e() {
                return String.fromCharCode(94 * Math.random() + 33)
                }
                function n(r) {
                for (var n = document.createDocumentFragment(), i = 0; r > i; i++) {
                    var l = document.createElement("span");
                    l.textContent = e(), l.style.color = t(), n.appendChild(l)
                }
                return n
                }
                function i() {
                var t = o[c.skillI];
                c.step ? c.step-- : (c.step = g, c.prefixP < l.length ? (c.prefixP >= 0 && (c.text += l[c.prefixP]), c.prefixP++) : "forward" === c.direction ? c.skillP < t.length ? (c.text += t[c.skillP], c.skillP++) : c.delay ? c.delay-- : (c.direction = "backward", c.delay = a) : c.skillP > 0 ? (c.text = c.text.slice(0, -1), c.skillP--) : (c.skillI = (c.skillI + 1) % o.length, c.direction = "forward")), r.textContent = c.text, r.appendChild(n(c.prefixP < l.length ? Math.min(s, s + c.prefixP) : Math.min(s, t.length - c.skillP))), setTimeout(i, d)
                }
                var l = "",
                o = ["青青陵上柏，磊磊涧中石。", "人生天地间，忽如远行客。","斗酒相娱乐，聊厚不为薄。", "驱车策驽马，游戏宛与洛。","洛中何郁郁，冠带自相索。","长衢罗夹巷，王侯多第宅。","两宫遥相望，双阙百余尺。","极宴娱心意，戚戚何所迫？"].map(function (r) {
                return r + ""
                }),
                a = 2,
                g = 1,
                s = 5,
                d = 75,
                b = ["rgb(110,64,170)", "rgb(150,61,179)", "rgb(191,60,175)", "rgb(228,65,157)", "rgb(254,75,131)", "rgb(255,94,99)", "rgb(255,120,71)", "rgb(251,150,51)", "rgb(226,183,47)", "rgb(198,214,60)", "rgb(175,240,91)", "rgb(127,246,88)", "rgb(82,246,103)", "rgb(48,239,130)", "rgb(29,223,163)", "rgb(26,199,194)", "rgb(35,171,216)", "rgb(54,140,225)", "rgb(76,110,219)", "rgb(96,84,200)"],
                c = {
                text: "",
                prefixP: -s,
                skillI: 0,
                skillP: 0,
                direction: "forward",
                delay: a,
                step: g
                };
                i()
            };
            binft(document.getElementById('binft'));
        },
	}
    
</script>

<style scoped>
.site_info{
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
    margin-top: 50px;
    margin-bottom: 100px;
}
.site_info img{
    margin-bottom: 20px;
    width: 50px;
}
.site_info p{
    color: #B9B9B9;
    font-weight: 600;
}
</style>