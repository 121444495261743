<template>
    <div>
        <div class="bread">
            <span class="chinese_font">
                <!-- 积分商城 -->
                <router-link to="/Shop_index/Shop_shop" class="bread_text"
                    active-class="active">积分商城
                </router-link>
                <span> | </span>
                <!-- 发布商品 -->
                <router-link to="/Shop_index/Shop_audit" class="bread_text"
                    active-class="active">发布商品
                </router-link>
                <span> | </span>
                <!-- 原神抽卡 -->
                <router-link to="/Shop_index/Shop_card" class="bread_text"
                    active-class="active">坤神抽卡
                </router-link>
                <span> | </span>
                <!-- 交易记录 -->
                <router-link to="/Shop_index/Shop_history" class="bread_text"
                    active-class="active">交易记录
                </router-link>
            </span>
        </div>
        <router-view></router-view>
    </div>
    </template>
    
    <script>
    export default {
        name:'ShopIndex',
    }
    </script>
    
    <style scoped>
    .bread{
        text-align:center;
        margin-top: 12vh;
    }
    .bread_text{
        font-size: 25px;
    }
    .bread_text:hover{
        color: dimgray;
    }
    .active{
        color: #ff8000 !important;
    }
    .bg{
        position: fixed;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }
    </style>